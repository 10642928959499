import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import "./common.css";
import { useEffect, forwardRef, useState, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
//import istudiologo from "../../images/vslogo.png";
//import waiting from "../../images/waiting.png";
import noimage from "../../images/nopreview.png";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RingVolumeOutlinedIcon from "@mui/icons-material/RingVolumeOutlined";
import PhoneDisabledOutlinedIcon from "@mui/icons-material/PhoneDisabledOutlined";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import PhoneForwardedOutlinedIcon from "@mui/icons-material/PhoneForwardedOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { DateObject } from "react-multi-date-picker";
import Expertsettings from "./softphone/expertsettings";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Overlay, Popover } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { ToastContainer, toast } from "react-toastify";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import RemoveShoppingCartOutlinedIcon from "@mui/icons-material/RemoveShoppingCartOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
//import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";
import {
  Button,
  Slide,
  Dialog,
  Stack,
  AppBar,
  Box,
  Toolbar,
  TextField,
  FormControl,
  TextareaAutosize,
  Divider,
} from "@mui/material";
import {
  getItemlist,
  createorder,
  calloperations,
  agentstatusupdate,
  makeconferencecall,
  GetItemddl,
  getCustname,
  Loaditeminfo,
  Loadpizzasize,
  Loadpizzabase,
  Loadpizzatoppings,
  Playaudiofile,
  Stopaudiofile,
} from "../../store/action/index";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

//import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
// import ListSubheader from "@mui/material/ListSubheader";

//import InfoIcon from "@mui/icons-material/Info";
//import { height } from "@mui/system";
//import { ThreeDRotation } from "@mui/icons-material";
export const useMountEffect = (fun) => useEffect(fun, []);
// Gneral Focus Hook
const UseFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };
  return [htmlElRef, setFocus];
};
console.Warning = () => {};
console.warn = () => {};
console.log = function () {};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const MenuTransition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
var tc = 0;
var ta = 0;
const initialState = 0;
const initialCount = 0;
const initialAmount = 0;
// const reducer = (state, action) => {
//   switch (action) {
//     case "A":
//       return state + 1;
//     case "R":
//       return state - 1;
//   }
// };
const reducer = (p) => !p;
const Home = ({
  getItemlist,
  createorder,
  calloperationsinfo,
  agentstatusupdate,
  conferencecall,
  getItemddl,
  getCustname,
  loaditeminfo,
  loadPizzasize,
  loadPizzabase,
  loadPizzatoppings,
  playaudiofile,
  stopaudiofile,
  state,
}) => {
  const [, render] = useReducer(reducer, false);

  const [count, countDispatch] = useReducer(reducer, initialCount);
  const [amount, amountDispatch] = useReducer(reducer, initialAmount);
  const [searchval, setSearchval] = useState("");
  const [searchval1, setSearchval1] = useState("");
  const [tempval, setTempval] = useState("");
  const [tempval1, setTempval1] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [altmobile, setAltmobile] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [statename, setStatename] = useState("");
  const [zip, setzip] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  let itemlist = [];
  var itemlistclone = [];
  const [stateitemlist, setStateitemlist] = useState([]);
  const [totalamount, setTotalamount] = useState(0);
  const [totalitems, setTotalitems] = useState(0);
  const [modelitem, setModelitem] = useState(0);
  const [modelitem1, setModelitem1] = useState(0);
  const [orgitemlist, setOrgitemlist] = useState([]);
  const [callclass, setCallclass] = useState(false);
  const [callbtn, setCallbtn] = useState(true);
  const [hubtn, setHubtn] = useState(true);
  const [customermobile, setCustomermobile] = useState("");
  const [outgoing, setOutgoing] = useState(true);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [ddldata, setDdldata] = useState([]);
  const [ddlname, setDdlname] = useState("");
  const [ddlname1, setDdlname1] = useState("");
  const [btnactive, setBtnactive] = useState(false);
  const [btnid, setBtnid] = useState(0);
  const [spicycnt, setSpicycnt] = useState(0);
  const [custname, setCustname] = useState("");
  const [category, setCategory] = useState("");
  const [play, setPlay] = useState(false);
  const [url, setUrl] = useState("");
  const [itemdata, setItemdata] = useState("");
  const [viewItem, setViewitem] = useState(false);
  const [viewsteper, setViewsteper] = useState(false);
  const [steps, setSteps] = useState([]);
  const audioRef = useRef(new Audio(url));
  const [selecteditems, setSelecteditems] = useState([]);
  const [baseview, setBaseview] = useState(false);
  const [defaulttoppingview, setDefaulttopping] = useState(false);
  const [toppingview, setToppingview] = useState(false);
  const [quantitycount, setQuantitycount] = useState(1);
  const [spicycount, setSpicycount] = useState(0);
  const [ispackage, setIspackage] = useState(false);
  const [packagename, setPackagename] = useState("");
  const [packagedesc, setPackagedesc] = useState("");
  const [packagcode, setPackagcode] = useState(0);
  const [packagerowdata, setPackagerowdata] = useState([]);
  const [selectedamount, setSelectedamount] = useState(0.0);
  const [sizeamt, setSizeamt] = useState(0.0);
  const [toppingamt, setToppingamt] = useState(0.0);
  const [queue, setQueue] = useState("101");
  const [post, setpost] = useState(0);
  const [cindex, setcindex] = useState(0);
  const [spicyinfo, setspicyinfo] = new useState([]);
  const inputSearchRef = useRef(null);
  const [selectedspicy, setSelectedspicy] = useState("");
  const [input1Ref, setInput1Focus] = UseFocus();
  const [note, setNote] = useState("");
  useMountEffect(setInput1Focus);
  // var selecteditemsarray = [];
  const handleChange = (event) => {
    setDdlname1(event.target.textContent);
    if (event.target.textContent === "0") {
      setDdlname("");
    } else {
    }
    if (event.target.textContent === "Combo") {
      //   setDdlname("");
      setIspackage(true);
    } else {
      setIspackage(false);
    }
  };

  let navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [enablenext, setEnablenext] = useState(true);
  /*Pizza */
  const [pizzasizevalue, setPizzasizevalue] = useState("");
  const [pizzabasevalue, setPizzabasevalue] = useState("");
  const [pizzaremmovevalue, setPizzaremmovevalue] = useState("");
  const [pizzadefaultremovevalue, setPizzadefaultremovevalue] = useState(0);
  const [pizzaextratoppingsvalue, setPizzaextratoppingsvalue] = useState(0);

  const [pizzasize, setPizzasize] = useState([
    // { sizeid: 1, sizename: "Size One" },
    // { sizeid: 2, sizename: "Size Two" },
    // { sizeid: 3, sizename: "Size Three" },
  ]);
  const [pizzabase, setPizzabase] = useState([
    // { baseid: 1, basename: "Base One" },
    // { baseid: 2, basename: "Base Two" },
    // { baseid: 3, basename: "Base Three" },
  ]);
  const [pizzadefaultremove, setPizzadefaultremove] = useState([
    // { removeid: 1, removename: "Base One" },
    // { removeid: 2, removename: "Base Two" },
    // { removeid: 3, removename: "Base Three" },
  ]);
  const [pizzaextratoppings, setPizzaextratoppings] = useState([
    // { toppingid: 1, toppingname: "Topping One1" },
    // { toppingid: 2, toppingname: "Topping Two2" },
    // { toppingid: 3, toppingname: "Topping Three3" },
  ]);
  const [extratoppings, setExtratoppings] = useState([
    { toppingid: 1, toppingname: "Whole", checked: false, toppinglist: "" },
    {
      toppingid: 2,
      toppingname: "First Half",
      checked: false,
      toppinglist: "",
    },
    {
      toppingid: 3,
      toppingname: "Second Half",
      checked: false,
      toppinglist: "",
    },
  ]);
  const [toppingcategroy, setToppingcategory] = useState("");
  const [defaultremovecount, setDefaultremovecount] = useState(0);
  const [removecountenable, setRemovecountenable] = useState(false);
  const [showtoppings, setShowtoppings] = useState(false);
  const [addontoppingslist, setAddontoppingslist] = useState([]);
  const [wholepizzachk, setWholepizzachk] = useState(false);
  const [firstpizzachk, setFirstpizzachk] = useState(false);
  const [secondpizzachk, setSecondpizzachk] = useState(false);
  const [tempstate, setTempstate] = useState(true);
  const [customerinstruction, setCustomerinstruction] = useState("");
  const [pizzainfo, setPizzainfo] = useState([]);
  var addontoppingslistarray = [];
  let removecount = 0;
  const [iteminfo, setIteminfo] = useState([]);
  const [isimage, setIsimage] = useState(true);
  const [modeltype, setModeltype] = useState(0);
  const [packagevalues, setPackagevalues] = useState("");
  const [defaultrowdata, setDefaultrowdata] = useState([]);
  const [itemoftheday, setItemoftheday] = useState(false);
  const [itempromotion, setItempromotion] = useState(false);
  const [itemdelay, setItemdelay] = useState(false);
  const [itemactive, setItemactive] = useState(false);
  const [resttype, setResttype] = useState("1");
  const [categroyactive, setCategoryactive] = useState(false);
  const [isownpizza, setIsownpizza] = useState(false);

  const [tempValue, setTempValue] = useState(false);
  const [temp, setTemp] = useState(0);
  const tempfun = () => {
    setTempValue(Math.random());
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    setQuantitycount(1);
    setSpicycount(0);
    setEnablenext(true);
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    refreshfun();
    var selecteditemsarray = [];
    var ischecked = "";
    let prevlen = 0;
    if (activeStep === steps.length - 1)
      for (let a = 0; a < steps.length; a++) {
        var obj = steps[a].itemtypelist.filter((item) => item.checked);
        for (let b = 0; b < obj.length; b++) {
          selecteditemsarray.push(obj[b]);
          if (b == 0) {
            selecteditemsarray[prevlen].itemtype = steps[a].itemtype;
          }
        }
        prevlen = selecteditemsarray.length;
        if (modeltype == 2) {
          setModeltype(3);
        }
      }
    //setSelecteditems(prevArray => [...prevArray, selecteditemsarray])
    setSelecteditems(selecteditemsarray);
    var obj = steps[activeStep].itemtypelist.filter((item) => item.checked);
    if (obj.length === 0) {
      setEnablenext(true);
    } else {
      setEnablenext(false);
    }
    setPizzainfo([]);
    refreshfun();
    if (selecteditemsarray.length - 1 === activeStep) {
      setEnablenext(false);
    } else {
      setEnablenext(true);
    }
  };

  const handleBack = () => {
    setcindex(activeStep - 1);
    var obj = steps[activeStep - 1].itemtypelist.filter((item) => item.checked);
    if (obj.length == 0) setEnablenext(true);
    else setEnablenext(false);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setEnablenext(true);
    setActiveStep(0);
    setQuantitycount(1);
    setSpicycount(0);
    setExtratoppings([]);
    var len1 = steps.length;
    var len2 = steps.length;
    for (let b = 0; b < len1; b++) {
      for (let a = 0; a < steps[b].itemtypelist.length; a++) {
        steps[b].itemtypelist[a].checked = false;
        steps[b].itemtypelist[a].packageval = "";
        refreshfun();
      }
    }
    setSteps(steps);
  };

  useEffect(() => {
    if (!window.localStorage.getItem("custmobile")) {
      setMobile(window.localStorage.getItem("custmobile"));
    }
    document.getElementById("tblcallcontainter").style.display = "flex";
    const interval = setInterval(function () {
      checkDID(interval);
    }, 1000);
  }, []);

  const slider = (interval) => {
    clearInterval(interval);
    var swiper = new Swiper(".swiper-container", {
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      slidesPerView: 5,
      spaceBetween: 10,
      autoplay: 3500,
      autoplayDisableOnInteraction: false,
      loop: false,
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    });
  };
  const handleProfileMenuOpen = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  const checkDID = (interval) => {
    var DID = window.localStorage.getItem("incommingDID");
    if (DID != null && DID != undefined && DID > 0) {
      setSearchval("");
      setNote("");
      setCategoryactive("");
      setCategory("");
      setDdlname("");
      setItemoftheday(false);
      setItemoftheday(false);
      setItemdelay(false);
      setItemactive(false);
      clearInterval(interval);
      setCallbtn(false);
      setOutgoing(false);
      setHubtn(false);
      if (temp === 0) {
        loadgrid();
      }
    } else {
      setOrgitemlist([]);
      setDdldata([]);
      setOutgoing(true);
      setStateitemlist([]);
    }
  };
  window.showAlert = () => {
    if (document.getElementById("btnincomming")) {
      document
        .getElementById("btnincomming")
        .classList.remove("gentle-hover-shake");
    }
    setCallbtn(true);
    setHubtn(true);
    setOrgitemlist([]);
    setDdldata([]);
    setOutgoing(true);
    setStateitemlist([]);
    refreshfun();
    document.getElementById("txtcustname").innerText = "";
    document.getElementById("txtrestname").innerText = "";
    setDdlname("");
    const interval = setInterval(function () {
      checkDID(interval);
    }, 1000);
  };
  const makecall = (type) => {
    if (type === 1) {
      if (document.getElementById("btnincomming")) {
        document
          .getElementById("btnincomming")
          .classList.remove("gentle-hover-shake");
      }
      sipCall("call-audio");
      setCallbtn(true);
      setHubtn(false);
      refreshfun();
    } else {
      sipHangUp();
      if (document.getElementById("btnincomming")) {
        document
          .getElementById("btnincomming")
          .classList.remove("gentle-hover-shake");
      }
      document.getElementById("txtcustname").innerText = "";
      document.getElementById("txtrestname").innerText = "";
      setCallbtn(true);
      setHubtn(true);
      setOrgitemlist([]);
      setStateitemlist([]);
      setDdldata([]);
      setOutgoing(true);
      setDdlname("");
      refreshfun();
    }
    refreshfun();
    calloperationsdata(type);
  };

  const loadgrid = () => {
    setpost(0);
    if (inputSearchRef.current != null && inputSearchRef.current != undefined) {
      inputSearchRef.current.focus();
    }
    let params = {
      UserId: state.loginreducer.logininfo.data.resultSet.agentid,
      DID: window.localStorage.getItem("incommingDID"),
    };
    setIspackage(false);
    setDdlname("");
    getItemlist(params)
      .then((response) => {
        if (response.data.resultset[0].poslink === "") {
          if (response.data.resultset.length > 0) {
            window.localStorage.setItem("islogedin", true);
            for (let i = 0; i < response.data.resultset.length; i++) {
              response.data.resultset[i]["itemcount"] = 1000;
            }
            setOrgitemlist(response.data.resultset);
            setCustomermobile(response.data.resultset[0].customermobile);
            document.getElementById("txtrestname").innerText =
              response.data.resultset[0].restname;
            let info = {
              custmobile: window.localStorage.getItem("custmobile"),
              customermobile: response.data.resultset[0].customermobile,
              did: window.localStorage.getItem("incommingDID"),
            };
            getCustname(info)
              .then((res) => {
                if (res.data.resultset != "") setCustname(res.data.resultset);
                document.getElementById("txtcustname").innerText =
                  "Speaking with " + res.data.resultset;
              })
              .catch((err) => {});

            let params1 = {
              DID: window.localStorage.getItem("incommingDID"),
            };

            getItemddl(params1)
              .then((response) => {
                for (let i = 0; i < response.data.data.length; i++) {
                  response.data.data[i]["ischecked"] = false;
                }
                setDdldata(response.data.data);
                setResttype(response.data.item[0].message);
                if (response.data.item[0].message == "1") {
                  setModeltype(3);
                }
              })
              .catch((error) => {});
          } else {
          }
        } else {
          if (temp === 0) {
            window.open(response.data.resultset[0].poslink, "_blank");
          }
          tempfun();
          setTimeout(() => {
            setTemp(1);
          }, 2000);
          tempfun();
        }
      })
      .catch((error) => {
        // console.log("error: ", error);
      });
  };

  const calloperationsdata = (type) => {
    let params = {
      did: window.localStorage.getItem("incommingDID"),
      agentid: state.loginreducer.logininfo.data.resultSet.agentid,
      extension: JSON.parse(
        state.loginreducer.logininfo.data.resultSet.configdata
      )[0].agentextension,
      incall: type,
    };
    calloperationsinfo(params)
      .then((response) => {
        if (response.data.status > 0) {
          // toast.success(response.data.message, {
          //   autoClose: 2000,
          // });
        } else {
          // toast.error(response.data.message, {
          //   autoClose: 2000,
          // });
        }
      })
      .catch((error) => {
        // console.log("error: ", error);
      });
  };
  const [values, setValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(0, "days"),
  ]);

  const [opensetting, setOpensetting] = useState(false);
  const [openordersetting, setOpenordersetting] = useState(false);
  const openExpertsettings = () => {
    setOpensetting(true);
  };
  const closeExpertsettings = () => {
    setOpensetting(false);
  };
  const logout = () => {
    sipUnRegister();
    window.localStorage.setItem("org.doubango.identity.display_name", "");
    window.localStorage.setItem("org.doubango.identity.impi", "");
    window.localStorage.setItem("org.doubango.identity.impu", "");
    window.localStorage.setItem("org.doubango.identity.password", "");
    window.localStorage.setItem("org.doubango.identity.realm", "");

    window.localStorage.setItem("org.doubango.expert.disable_video", "");
    window.localStorage.setItem(
      "org.doubango.expert.enable_rtcweb_breaker",
      ""
    );

    window.localStorage.setItem("org.doubango.expert.websocket_server_url", "");

    window.localStorage.setItem(
      "org.doubango.expert.sip_outboundproxy_url",
      ""
    );
    window.localStorage.setItem("org.doubango.expert.ice_servers", "");
    window.localStorage.setItem("org.doubango.expert.bandwidth", "");
    window.localStorage.setItem("org.doubango.expert.video_size", "");
    window.localStorage.setItem("org.doubango.expert.disable_early_ims", "");
    window.localStorage.setItem("org.doubango.expert.disable_debug", "");
    window.localStorage.setItem("org.doubango.expert.enable_media_caching", "");
    window.localStorage.setItem(
      "org.doubango.expert.disable_callbtn_options",
      ""
    );
    window.localStorage.setItem("islogedin", false);
    window.localStorage.setItem("incommingDID", 0);
    document.getElementById("txtcustname").innerText = "";
    document.getElementById("txtrestname").innerText = "";
    setDdlname("");
    let param = {
      agentid: state.loginreducer.logininfo.data.resultSet.agentid,
      extension: JSON.parse(
        state.loginreducer.logininfo.data.resultSet.configdata
      )[0].agentextension,
      registered: "Disconnected",
    };
    agentstatusupdate(param)
      .then((res) => {})
      .catch((ex) => {});
    const interval = setInterval(function () {
      clearInterval(interval);
      navigate("/");
    }, 1000);
  };
  const refreshfun = () => {
    let a = Date.now().toString();
    setTempval(a);
    setTempval1(a);
  };

  const calculateamout = (qty, from) => {
    debugger;
    if (modeltype == 2 || modeltype == 0 || from == 1) {
      if (activeStep === 0) {
        var newArray;
        if (pizzainfo.length == 0) {
          newArray = extratoppings.filter(function (el) {
            return el.checked;
          });
        } else {
          newArray = pizzainfo.filter(function (el) {
            return el.checked;
          });
        }
        if (newArray.length === 0)
          newArray = extratoppings.filter(function (el) {
            return el.checked;
          });
        let amt = 0;
        let samt = 0;
        let tamt = 0;
        let pizsize = "";

        for (let i = 0; i < newArray.length; i++) {
          amt = amt + newArray[i].amount;
          tamt = tamt + newArray[i].amount;
        }
        tamt = tamt * qty;
        setToppingamt(tamt.toFixed(2));
        amt = (amt + parseFloat(sizeamt)) * qty;
        setSelectedamount(amt.toFixed(2));
        reducer();
      } else {
        //console.log("selecteditems", selecteditems);
        var newArray;
        if (pizzainfo.length == 0) {
          newArray = extratoppings.filter(function (el) {
            return el.checked;
          });
        } else {
          newArray = pizzainfo.filter(function (el) {
            return el.checked;
          });
        }
        if (newArray.length === 0)
          newArray = extratoppings.filter(function (el) {
            return el.checked;
          });
        let amt = 0;
        let samt = 0;
        let tamt = 0;
        let pizsize = "";

        for (let i = 0; i < newArray.length; i++) {
          amt = amt + newArray[i].amount;
          tamt = tamt + newArray[i].amount;
        }
        tamt = parseFloat(toppingamt) + tamt * qty;
        amt = parseFloat(toppingamt) + amt * qty;
        setToppingamt(tamt.toFixed(2));
        amt = (amt + parseFloat(sizeamt)) * qty;
        setSelectedamount(amt.toFixed(2));
        reducer();
      }
    } else {
      var newArray;
      if (pizzainfo.length == 0) {
        newArray = extratoppings.filter(function (el) {
          return el.checked;
        });
      } else {
        newArray = pizzainfo.filter(function (el) {
          return el.checked;
        });
      }
      var newArray1 = pizzasize.filter(function (el) {
        return el.checked;
      });
      let amt = 0;
      let samt = 0;
      let tamt = 0;
      let pizsize = "";
      for (let i = 0; i < newArray1.length; i++) {
        amt = amt + newArray1[i].price;
        samt = samt + newArray1[i].price;
        pizsize = newArray1[i].sizename;
      }
      samt = samt * qty;
      if (modeltype != 2) setSizeamt(samt.toFixed(2));
      for (let i = 0; i < newArray.length; i++) {
        amt = amt + newArray[i].amount;
        tamt = tamt + newArray[i].amount;
      }
      tamt = tamt * qty;
      setToppingamt(tamt.toFixed(2));
      amt = amt * qty;
      setSelectedamount(amt.toFixed(2));
      reducer();
    }
  };

  const calculateamoutinit = (qty, from, amount) => {
    if (modeltype == 2 || modeltype == 0 || from == 1) {
      if (activeStep === 0) {
        var newArray;
        if (pizzainfo.length == 0) {
          newArray = extratoppings.filter(function (el) {
            return el.checked;
          });
        } else {
          newArray = pizzainfo.filter(function (el) {
            return el.checked;
          });
        }
        if (newArray.length === 0)
          newArray = extratoppings.filter(function (el) {
            return el.checked;
          });
        let amt = 0;
        let samt = 0;
        let tamt = 0;
        let pizsize = "";

        for (let i = 0; i < newArray.length; i++) {
          amt = amt + newArray[i].amount;
          tamt = tamt + newArray[i].amount;
        }
        tamt = tamt * qty;
        setToppingamt(tamt.toFixed(2));
        amt = (amt + parseFloat(sizeamt)) * qty;
        setSelectedamount(amt.toFixed(2));
        reducer();
      } else {
        //console.log("selecteditems", selecteditems);
        var newArray;
        if (pizzainfo.length == 0) {
          newArray = extratoppings.filter(function (el) {
            return el.checked;
          });
        } else {
          newArray = pizzainfo.filter(function (el) {
            return el.checked;
          });
        }
        if (newArray.length === 0)
          newArray = extratoppings.filter(function (el) {
            return el.checked;
          });
        let amt = 0;
        let samt = 0;
        let tamt = 0;
        let pizsize = "";

        for (let i = 0; i < newArray.length; i++) {
          amt = amt + newArray[i].amount;
          tamt = tamt + newArray[i].amount;
        }
        tamt = parseFloat(toppingamt) + tamt * qty;
        amt = parseFloat(toppingamt) + amt * qty;
        setToppingamt(tamt.toFixed(2));
        amt = (amt + parseFloat(sizeamt)) * qty;
        setSelectedamount(amt.toFixed(2));
        reducer();
      }
    } else {
      var newArray;
      if (pizzainfo.length == 0) {
        newArray = extratoppings.filter(function (el) {
          return el.checked;
        });
      } else {
        newArray = pizzainfo.filter(function (el) {
          return el.checked;
        });
      }
      var newArray1 = pizzasize.filter(function (el) {
        return el.checked;
      });
      let amt = 0;
      let samt = 0;
      let tamt = 0;
      let pizsize = "";
      for (let i = 0; i < newArray1.length; i++) {
        amt = amt + newArray1[i].price;
        samt = samt + newArray1[i].price;
        pizsize = newArray1[i].sizename;
      }
      samt = amount;
      samt = samt * qty;
      if (modeltype != 2) setSizeamt(samt.toFixed(2));
      for (let i = 0; i < newArray.length; i++) {
        amt = amt + newArray[i].amount;
        tamt = tamt + newArray[i].amount;
      }
      tamt = tamt * qty;
      setToppingamt(tamt.toFixed(2));
      amt = amt * qty;
      setSelectedamount(amt.toFixed(2));
      reducer();
    }
  };
  const selectobj = (row, type, position) => {
    setpost(0);
    setPizzasizevalue("");
    if (inputSearchRef.current != null && inputSearchRef.current != undefined) {
      inputSearchRef.current.focus();
    }

    debugger;
    if (modeltype == 3) {
      row = defaultrowdata;
      refreshfun();
    }
    var newArray = pizzainfo.filter(function (el) {
      return el.checked;
    });
    var newArray1 = pizzasize.filter(function (el) {
      return el.checked;
    });
    if (newArray1.length <= 0) {
      toast.error("Please select size", {
        autoClose: 2000,
      });
      return false;
    } else {
      setViewitem(false);
    }
    let toppinglist = "";
    let amt = 0;
    let pizsize = "";
    let sizeid = 0;
    for (let i = 0; i < newArray1.length; i++) {
      amt = amt + newArray1[i].price;
      pizsize = newArray1[i].sizename;
      sizeid = newArray1[i].sizeid;
    }
    for (let i = 0; i < newArray.length; i++) {
      amt = amt + newArray[i].amount;
      if (i === 0)
        toppinglist = newArray[i].toppingname + " - " + newArray[i].toppinglist;
      else
        toppinglist =
          toppinglist +
          ", " +
          newArray[i].toppingname +
          " - " +
          newArray[i].toppinglist;
    }
    if (modeltype == 3) {
      amt = parseFloat(selectedamount);
      refreshfun();
    } else amt = amt * quantitycount;
    let size = "",
      base = "",
      rem = "",
      tlist = "",
      spicylist = "";
    if (pizzabasevalue != "") base = ",base: " + pizzabasevalue;
    else base = "";

    if (pizzaremmovevalue != "") rem = ", " + pizzaremmovevalue;
    else rem = "";
    if (toppinglist != "") tlist = ", Add on Toppings- " + toppinglist;
    else tlist = "";

    spicylist = selectedspicy != "" ? " Spicy - " + selectedspicy : "";

    var remarks = "";
    let currentsize = pizzasizevalue;
    let currentbase = base;

    if (modeltype === 1) {
      if (currentsize != "") {
        remarks = "Size:" + currentsize + ",";
      }
      if (currentbase != "") {
        remarks = remarks + "Base:" + currentbase + ",";
      }
      if (rem != "") {
        remarks = remarks + rem + ",";
      }
      if (tlist != "") {
        remarks = remarks + tlist + ",";
      }
      if (spicylist != "") {
        remarks = remarks + spicylist + ",";
      }
      if (customerinstruction != "") {
        remarks = remarks + customerinstruction;
      }
      //remarks =  currentsize + base + rem + tlist + spicylist + " ~" + customerinstruction;
    } else if (modeltype == 3) {
      for (let index = 0; index < steps.length; index++) {
        for (let b = 0; b < steps[index].itemtypelist.length; b++) {
          if (index == 0 && b == 0) {
            remarks = steps[index].itemtypelist[b].packageval;
          } else {
            if (steps[index].itemtypelist[b].checked === true) {
              if (steps[index].itemtypelist[b].packageval == "") {
                remarks =
                  remarks +
                  steps[index].itemtypelist[b].itemtype +
                  ":" +
                  steps[index].itemtypelist[b].itemname;
              } else {
                remarks =
                  remarks + steps[index].itemtypelist[b].packageval + " ";
              }
            }
          }
        }
      }
    } else {
      //debugger;
      //let itemdesc = row.itemtype.toLowerCase().includes('half') ? row.itemtype : "[ Item- ";
      let itemdesc = row.itemtype.toLowerCase().includes("half")
        ? row.itemtype
        : row.itemtype + ":";
      let specialinstruction =
        customerinstruction !== ""
          ? ",Special instruction- " + customerinstruction
          : "";
      //let lstrspicy = spicycount > 0 ? ",Spicy- " + selectedspicy : "";
      // alert(selectedspicy);
      let lstrspicy = selectedspicy != "" ? ",Spicy- " + selectedspicy : "";
      remarks =
        itemdesc +
        " " +
        row.itemname +
        // ", Count- " +
        // quantitycount +
        ", Size- " +
        (pizzasizevalue == "" ? pizsize : pizzasizevalue) +
        " " +
        base +
        tlist +
        lstrspicy +
        specialinstruction +
        "]";
    }
    itemlistclone = stateitemlist;
    //debugger;
    if (type === "A") {
      let obj = {
        itemid: row.itemid !== undefined ? row.itemid : row.itemcode,
        itemname: row.itemname,
        itemcount: quantitycount,
        itemprice: amt.toFixed(2), //row.unitPrice +
        itemdescription: "",
        itemcode: row.itemcode,
        customeritemcode: row.customeritemcode,
        parentitemcode: 0,
        customerparentitemcode: "0",
        itemname: row.itemname,
        quantity: 0,
        unitprice: (amt.toFixed(2) / quantitycount).toFixed(2), // row.unitPrice +
        grossamount: 0,
        taxamount: 0,
        totalamount: 0,
        isaddon: 0,
        ismodifier: 0,
        itemserial: 0,
        serial: 0,
        packageitem: row.packageitem,
        packageitemdetails: row.packageitemdetails,
        remarks:
          resttype == "-1"
            ? ""
            : remarks
                .replaceAll("[", "")
                .replaceAll("]", "")
                .replaceAll("Size- .", "") + " ",
        itemsize: sizeid,
        sizename: resttype == "-1" ? row.sizename : pizzasizevalue,
        description: row.description,
        spicy: spicycount,
        spicyname: selectedspicy,
        pkg: selecteditems,
      };

      setSearchval("");
      refreshfun();

      itemlistclone = stateitemlist;

      if (resttype == "-1") itemlistclone.push(obj);
      else {
        if (modeltype == 1 || modeltype == 3) {
          itemlistclone.push(obj);
        } else setPackagevalues((oldval) => oldval + ", " + remarks);
      }
      if (modeltype == 3) {
        setPizzainfo([]);
        refreshfun();
      }
      setStateitemlist(itemlistclone);
      refreshfun();

      for (let a = cindex; a < steps.length; a++) {
        var checkedlist = steps[a].itemtypelist.findIndex(
          (item) => row.itemid == item.itemid
        );
        if (checkedlist >= 0) {
          steps[a].itemtypelist[checkedlist].packageval = remarks;
          setSteps(steps);
          refreshfun();
          break;
        }
      }
      console.log("itemclone", itemlistclone);

      for (let i = 0; i < itemlistclone.length; i++) {
        if (itemlistclone.length == 1) {
          tc = 1;
          tc = itemlistclone[i].itemcount;

          ta = parseFloat(itemlistclone[i].itemprice);
        } else {
          if (i == 0) {
            tc = 0;
            ta = 0;
          }
          tc = tc + itemlistclone[i].itemcount;
          // tc = tc + 1;
          ta = ta + parseFloat(itemlistclone[i].itemprice);
        }

        setTotalitems(tc);
        setTotalamount(parseFloat(ta).toFixed(2));
        refreshfun();
        setQuantitycount(1);
        setSpicycount(0);
      }
    } else if (type === "R") {
      let index = itemlistclone.findIndex((a) => a.itemid === row.itemid);
      if (itemlistclone.length > 1) {
        index = position;
      }

      itemlistclone.splice(position, 1);
      setStateitemlist(itemlistclone);
      setSearchval1("");
      refreshfun();

      if (itemlistclone.length == 0) {
        setTotalitems(0);
        setTotalamount(0);
        refreshfun();
      }
      for (let i = 0; i < itemlistclone.length; i++) {
        if (itemlistclone.length == 1) {
          tc = itemlistclone[i].itemcount;
          ta = parseFloat(itemlistclone[i].itemprice);
        } else {
          if (i == 0) {
            tc = 0;
            ta = 0;
          }
          tc = tc + itemlistclone[i].itemcount;
          ta = ta + parseFloat(itemlistclone[i].itemprice);
        }

        setTotalitems(tc);
        setTotalamount(parseFloat(ta).toFixed(2));
        refreshfun();
      }
    }
    setSelectedspicy("");
    resetwindow();
  };
  const resetwindow = () => {
    setShowtoppings(false);
    setPizzaextratoppings([]);
  };
  const changespicy = (data, type, position) => {
    itemlistclone = stateitemlist;
    let spicylevel = 0;
    let index = itemlistclone.findIndex((a) => a.itemid === data.itemid);
    if (itemlistclone.length > 1) {
      index = position;
    }
    if (type == "I") {
      if (index >= 0) spicylevel = itemlistclone[index].spicy;
      if (itemlistclone[index].spicy >= 5) {
        spicylevel = 5;
        toast.error("Max level reached", {
          autoClose: 2000,
        });
      } else {
        spicylevel = spicylevel + 1;
      }
      itemlistclone[index].spicy = spicylevel;
    } else {
      if (index >= 0) spicylevel = itemlistclone[index].spicy;
      if (itemlistclone[index].spicy <= 0) {
        spicylevel = 0;
        toast.error("Min level reached", {
          autoClose: 2000,
        });
      } else {
        spicylevel = spicylevel - 1;
      }
      itemlistclone[index].spicy = spicylevel;
    }
    setStateitemlist(itemlistclone);
    refreshfun();
  };
  const changeCount = (data, type, position) => {
    // debugger;
    itemlistclone = stateitemlist;
    tc = 0;
    ta = 0;
    if (type == "I") {
      let index = itemlistclone.findIndex((a) => a.itemid === data.itemid);
      let index1 = orgitemlist.findIndex((a) => a.itemcode === data.itemid);
      let itemcnt = 0;
      if (itemlistclone.length > 1) {
        index = position;
      }
      if (index >= 0) itemcnt = itemlistclone[index].itemcount;
      if (orgitemlist[index1].itemcount <= itemcnt) {
        itemcnt = itemlistclone[index1].itemcount;
        toast.error("Max stock reached", {
          autoClose: 2000,
        });
      } else {
        itemcnt = itemcnt + 1;
      }

      itemlistclone[index].itemcount = itemcnt;
      itemlistclone[index].itemprice = (
        itemcnt * parseFloat(itemlistclone[index].unitprice)
      ).toFixed(2);

      itemlistclone[index].quantity = itemcnt;
      itemlistclone[index].totalamount = (
        itemcnt * parseFloat(itemlistclone[index].unitprice)
      ).toFixed(2);
      itemlistclone[index].amount = parseFloat(itemlistclone[index].unitprice);
      setStateitemlist(itemlistclone);
      refreshfun();

      for (let i = 0; i < itemlistclone.length; i++) {
        if (itemlistclone.length == 1) {
          tc = itemlistclone[i].itemcount;
          //tc = 1;
          ta = parseFloat(itemlistclone[i].itemprice);
        } else {
          tc = tc + itemlistclone[i].itemcount;
          //tc = tc + 1;
          ta = ta + parseFloat(itemlistclone[i].itemprice);
        }
        setTotalitems(tc);
        setTotalamount(parseFloat(ta).toFixed(2));

        refreshfun();
      }
    } else if (type == "D") {
      let index = itemlistclone.findIndex((a) => a.itemid === data.itemid);
      let index1 = orgitemlist.findIndex((a) => a.itemcode === data.itemid);
      index = position;
      let itemcnt = 0;
      itemcnt = data.itemcount;
      if (itemcnt === 1) {
        // itemcnt = itemlistclone[index1].itemcount;
        toast.error("Min stock reached", {
          autoClose: 2000,
        });
      } else {
        itemcnt = data.itemcount - 1;

        itemlistclone[index].itemcount = itemcnt;
        itemlistclone[index].itemprice = (
          itemcnt * parseFloat(itemlistclone[index].unitprice)
        ).toFixed(2);

        itemlistclone[index].quantity = itemcnt;
        itemlistclone[index].totalamount = (
          itemcnt * parseInt(itemlistclone[index].unitprice)
        ).toFixed(2);
        itemlistclone[index].amount = parseFloat(
          parseFloat(itemlistclone[index].unitprice)
        );
        setStateitemlist(itemlistclone);
        refreshfun();

        for (let i = 0; i < itemlistclone.length; i++) {
          if (itemlistclone.length == 1) {
            tc = itemlistclone[i].itemcount;
            // tc = 1;
            ta = itemlistclone[i].itemprice;
          } else {
            tc = tc + itemlistclone[i].itemcount;
            // tc = tc + 1;
            ta = parseFloat(ta) + parseFloat(itemlistclone[i].itemprice);
            //alert(itemlistclone[i].itemprice);
          }
          setTotalitems(tc);
          setTotalamount(parseFloat(ta).toFixed(2));

          refreshfun();
        }
      }
    }
  };
  const previewitems = () => {
    if (stateitemlist.length > 0) {
      setMobile(window.localStorage.getItem("custmobile"));
      setOpenordersetting(true);
    } else {
      toast.error("Please add items", {
        autoClose: 2000,
      });
    }
  };
  //
  const saveorder = () => {
    try {
      setpost(1);
      let tempname = "";
      if (custname != "") {
        tempname = custname;
      } else if (name != "") {
        tempname = name;
      } else {
        tempname = "";
      }
      var consumerobj = {
        isnew: 1,
        name: custname,
        mobile: mobile,
        altmobile: altmobile,
        address1: address.split(",")[0],
        address2: "",
        city: city,
        state: statename,
        zipcode: zip,
        queuename: queue,
      };
      let len = stateitemlist.length;
      let temparr = [];
      debugger;
      for (let j = 0; j < len; j++) {
        let tempobj = {
          itemcode: 0,
          customeritemCode: "",
          parentitemCode: 0,
          customerparentitemCode: "",
          itemname: "",
          quantity: 0,
          unitprice: 0,
          grossamount: 0,
          taxamount: 0,
          totalamount: 0,
          isaddon: 0,
          ismodifier: 0,
          itemserial: 0,
          serial: 0,
          packageitem: 0,
          remarks: "",
          itemsize: 0,
          sizename: "",
          spicy: 0,
          spicydescription: "",
        };
        tempobj.itemcode = stateitemlist[j].itemcode;
        tempobj.customeritemCode = "";
        tempobj.parentitemCode = 0;
        tempobj.customerparentitemCode = "";
        tempobj.itemname = stateitemlist[j].itemname;
        tempobj.quantity = stateitemlist[j].itemcount;
        tempobj.unitprice = stateitemlist[j].itemprice;
        tempobj.grossamount = 0;
        tempobj.taxamount = 0;
        tempobj.totalamount = parseFloat(
          stateitemlist[j].itemcount * stateitemlist[j].itemprice
        );
        tempobj.isaddon = 0;
        tempobj.ismodifier = 0;
        tempobj.itemserial = stateitemlist[j].serialno;
        tempobj.serial = parseInt(j + 1);
        tempobj.packageitem = stateitemlist[j].packageitem;
        tempobj.remarks = stateitemlist[j].remarks;
        tempobj.itemsize = stateitemlist[j].itemsize;
        tempobj.sizename = stateitemlist[j].sizename;
        tempobj.spicy = stateitemlist[j].spicy;
        tempobj.spicydescription = stateitemlist[j].spicyname;
        temparr.push(tempobj);
        //selecteditems
        if (stateitemlist[j].packageitem > 0) {
          for (
            let i = 0;
            i < JSON.parse(stateitemlist[j].packageitemdetails).length;
            i++
          ) {
            let tempobj = {
              itemcode: 0,
              customeritemCode: "",
              parentitemCode: 0,
              customerparentitemCode: "",
              itemname: "",
              quantity: 0,
              unitprice: 0,
              grossamount: 0,
              taxamount: 0,
              totalamount: 0,
              isaddon: 0,
              ismodifier: 0,
              itemserial: 0,
              serial: 0,
              packageitem: 0,
              remarks: "",
              itemsize: 0,
              sizename: "",
              spicydescription: "",
            };
            // tempobj.itemcode = selecteditems[i].itemid;
            // tempobj.itemname = selecteditems[i].itemname;
            tempobj.itemcode = stateitemlist[j].pkg[i].itemid;
            tempobj.itemname = stateitemlist[j].pkg[i].itemname;
            tempobj.customeritemCode = "";
            tempobj.parentitemCode = JSON.parse(
              stateitemlist[j].packageitemdetails
            )[i].packageitemcode;

            tempobj.quantity = JSON.parse(stateitemlist[j].packageitemdetails)[
              i
            ].qty;
            tempobj.unitprice = JSON.parse(stateitemlist[j].packageitemdetails)[
              i
            ].unitprice;
            tempobj.grossamount = 0;
            tempobj.taxamount = 0;
            tempobj.totalamount = parseFloat(
              JSON.parse(stateitemlist[j].packageitemdetails)[i].qty *
                JSON.parse(stateitemlist[j].packageitemdetails)[i].unitprice
            );
            tempobj.isaddon = 0;
            tempobj.ismodifier = 0;
            tempobj.itemserial = parseInt(j + 1);
            tempobj.serial = parseInt(i + 1);
            tempobj.packageitem = 1;
            tempobj.remarks = stateitemlist[j].remarks;
            tempobj.itemsize = JSON.parse(stateitemlist[j].packageitemdetails)[
              i
            ].itemsizeid;
            tempobj.sizename = JSON.parse(stateitemlist[j].packageitemdetails)[
              i
            ].sizename;
            tempobj.spicydescription = stateitemlist[j].spicyname;
            temparr.push(tempobj);
          }
        }
      }

      let params = {
        did: window.localStorage.getItem("incommingDID"),
        customerid: 0,
        userid: state.loginreducer.logininfo.data.resultSet.agentid,
        consumer: consumerobj,
        consumerid: 0,
        // noofitems: tc,
        noofitems: temparr.length,
        grossamount: ta,
        taxamount: 0,
        totalamount: ta,
        itemdetails: temparr,
        ordernote: note,
      };

      if (mobile != "" && post == 0) {
        createorder(params)
          .then((response) => {
            setpost(0);
            if (response.data.status == 1) {
              toast.success(response.data.message, {
                autoClose: 2000,
                position: "top-center",
              });
            } else {
              toast.error(response.data.message, {
                autoClose: 2000,
                position: "top-center",
              });
            }
            tc = 0;
            ta = 0;
            setStateitemlist([]);
            setName("");
            setCustname("");
            setMobile("");
            setAltmobile("");
            setAddress("");
            setCity("");
            setStatename("");
            setzip("");
            setOpenordersetting(false);
            setSelectedspicy("");
            setNote("");
            setCategoryactive("");
            setCategory("");
            setDdlname("");
            setItemoftheday(false);
            setItemoftheday(false);
            setItemdelay(false);
            setItemactive(false);

            let wait = debounceFunc("wait");
          })
          .catch((error) => {
            setpost(0);
          });
      } else {
        setpost(0);
        toast.error("Please enter name and mobile", {
          autoClose: 1000,
        });
        setpost(0);
      }
    } catch (error) {
      alert(error);
      setpost(0);
    }
    //
  };
  let atBegin = true;
  const debounceFunc = debounce(
    8000,
    (value) => {
      sipHangUp();
      if (document.getElementById("btnincomming")) {
        document
          .getElementById("btnincomming")
          .classList.remove("gentle-hover-shake");
      }
      document.getElementById("txtcustname").innerText = "";
      document.getElementById("txtrestname").innerText = "";
      setpost(0);
      setCallbtn(true);
      setHubtn(true);
      setOrgitemlist([]);
      setStateitemlist([]);
      setDdldata([]);
      setOutgoing(true);
      setDdlname("");
      refreshfun();
    },
    { atBegin: false }
  );

  const connectconference = () => {
    if (customermobile != "") {
      setOutgoing(true);
      let params = {
        user: JSON.parse(
          state.loginreducer.logininfo.data.resultSet.configdata
        )[0].agentextension,
        customer: customermobile.replaceAll("+", ""),
      };
      conferencecall(params)
        .then((res) => {
          if (res.data.status === 1) {
            toast.success("Connecting...", {
              autoClose: 1000,
            });
          } else {
            toast.error("Unable to get connect resturant", {
              autoClose: 1000,
            });
          }
          setOutgoing(false);
        })
        .catch((err) => {});
    } else {
      toast.error("Unable to get resturant number", {
        autoClose: 1000,
      });
    }
  };
  const clearcheckbox = () => {
    var temp = document.querySelectorAll(".abc");
    for (let i = 0; i < temp.length; i++) {
      temp[i].classList.remove("currentbtn");
    }
    setDdlname("");
    setBtnactive(false);
    var clist = document.getElementsByTagName("input");
    for (var i = 0; i < clist.length; ++i) {
      clist[i].checked = false;
    }
    var a = ddldata;
    for (let j = 0; j < a.length; j++) {
      a[j].ischecked = false;
    }
  };
  const activebutton = (event, code, index) => {
    setBtnid(event.target.id);
    handleChange(event);
    var a = ddldata;
    for (let j = 0; j < a.length; j++) {
      if (j != index) a[j].ischecked = false;
    }

    if (event.target.id == code) {
      a[index].ischecked = !a[index].ischecked;
      if (a[index].ischecked) {
        setDdlname1(event.target.textContent);
        setDdlname(event.target.textContent);
      } else {
        setDdlname("");
        setIspackage(false);
      }
      setDdldata(a);
      refreshfun();
    } else {
      setBtnactive(false);
    }
    setSearchval("");
    setSearchval1("");
  };

  const playaudio = (option, src) => {
    if (option == 1) {
      let params = {
        conferenceid: JSON.parse(
          state.loginreducer.logininfo.data.resultSet.configdata
        )[0].agentextension,
        filename: src,
      };
      playaudiofile(params)
        .then((res) => {
          setPlay(true);
        })
        .catch((error) => {});
    } else {
      setPlay(false);
    }
  };

  const loadItemdata = (data, type, from) => {
    setpost(0);
    setModeltype(type);
    refreshfun();
    setPizzasize([]);
    setPizzadefaultremove([]);
    setPizzabase([]);
    setDefaultremovecount(0);
    setPizzaextratoppings([]);
    setExtratoppings([
      {
        toppingid: 1,
        toppingname: "Whole",
        checked: false,
        toppinglist: "",
        amount: 0,
      },
      {
        toppingid: 2,
        toppingname: "First Half",
        checked: false,
        toppinglist: "",
        amount: 0,
      },
      {
        toppingid: 3,
        toppingname: "Second Half",
        checked: false,
        toppinglist: "",
        amount: 0,
      },
    ]);
    setCustomerinstruction("");
    setspicyinfo([]);
    let itemcode = 0;
    if (type == 1) itemcode = data.itemcode;
    else itemcode = data.itemid;
    loaditeminfo(itemcode)
      .then((res) => {
        if (res.data.data[0].isown == 0) {
          setIsownpizza(false);
        } else {
          setIsownpizza(true);
        }

        setIteminfo(res.data.data[0]);
        removecount = 0;
        setItemdata(data);

        refreshfun();
        if (res.data.data[0].imagepath.endsWith("itemmasterimages/") == true)
          setIsimage(false);
        else setIsimage(true);
        if (res.data.data[0].sizeinfo != "") {
          if (from == 1) {
            let index = packagerowdata.findIndex(
              (a) => a.serialno === activeStep + 1
            );
            let sizeid = packagerowdata[index].itemsizeid;
            let ab = JSON.parse(res.data.data[0].sizeinfo);
            let index1 = ab.findIndex((a) => a.sizeid === sizeid);
            ab[index1].checked = true;
            refreshfun();
            var newArray = ab.filter(function (el) {
              return el.checked;
            });

            setPizzasize(newArray);
            refreshfun();
          } else {
            let amt = 0;
            let samt = 0;
            let tamt = 0;
            let qty = 1;

            let arrsize = JSON.parse(res.data.data[0].sizeinfo);
            setPizzasize(arrsize);
            refreshfun();
            if (arrsize.length > 0) {
              arrsize[0].checked = true;
              samt = arrsize[0].price;
            }
            setPizzasize(arrsize);
            setPizzasizevalue(arrsize[0].sizename);
            setQuantitycount(qty);
            samt = samt * qty;
            tamt = tamt * qty;
            setSizeamt(samt.toFixed(2));
            setToppingamt(tamt.toFixed(2));
            amt = amt * qty + samt;
            setSelectedamount(amt.toFixed(2));
            reducer();
            refreshfun();
          }
        }

        if (res.data.data[0].spicyinfo != "") {
          setspicyinfo(JSON.parse(res.data.data[0].spicyinfo));
          let arrSpicy = JSON.parse(res.data.data[0].spicyinfo);
          for (let i = 0; i < arrSpicy.length; i++) {
            if (arrSpicy[i].checked == true) {
              setSelectedspicy(arrSpicy[i].spicyname);
              setSpicycount(1);
            }
          }
        }

        if (res.data.data[0].baseinfo != "") {
          setPizzabase(JSON.parse(res.data.data[0].baseinfo));
          setBaseview(true);
        } else setBaseview(false);
        if (res.data.data[0].defaulttoppinginfo != "") {
          setPizzadefaultremove(
            JSON.parse(res.data.data[0].defaulttoppinginfo)
          );
          setDefaulttopping(true);
        } else setDefaulttopping(false);
        if (res.data.data[0].addonremovelimit != "")
          setDefaultremovecount(res.data.data[0].addonremovelimit);
        if (res.data.data[0].toppinginfo != "") {
          for (
            let a = 0;
            a < JSON.parse(res.data.data[0].toppinginfo).length;
            a++
          ) {
            JSON.parse(res.data.data[0].toppinginfo)[a].checked = false;
          }

          setPizzaextratoppings(JSON.parse(res.data.data[0].toppinginfo));
          setToppingview(true);
        } else setToppingview(false);
        setViewitem(true);
      })
      .catch((err) => {});

    // amal
    // setEnablenext(true);
  };
  const selectremovebase = (event, index) => {
    pizzabase[index].checked = !pizzabase[index].checked;
    refreshfun();
    let basevalue = "";

    var newArray = pizzabase.filter(function (el) {
      return el.checked;
    });
    if (newArray.length > defaultremovecount) {
      event.target.checked = false;
    }
    for (let a = 0; a < pizzabase.length; a++) {
      if (a == index) basevalue = pizzabase[a].basename;
    }
    setPizzabasevalue(basevalue);
  };
  const selectremoveoption = (event, index) => {
    pizzadefaultremove[index].checked = !pizzadefaultremove[index].checked;
    refreshfun();
    let basevalue = "";

    var newArray = pizzadefaultremove.filter(function (el) {
      return el.checked;
    });
    if (newArray.length > defaultremovecount) {
      event.target.checked = false;
    }
    for (let a = 0; a < newArray.length; a++) {
      if (a == 0) basevalue = "No " + newArray[a].toppingname;
      else basevalue = basevalue + ", No" + newArray[a].toppingname;
    }
    setPizzaremmovevalue(basevalue);
  };
  const openaddon = (e) => {
    setToppingcategory(e.target.value);
    if (activeStep === 0) {
      for (let a = 0; a < pizzaextratoppings.length; a++) {
        pizzaextratoppings[a].checked = false;
      }
    }
    if (e.target.checked) {
      setShowtoppings(true);
    } else {
      if (e.target.value == "Whole") {
        extratoppings.find((v) => v.toppingname === "Whole").toppinglist = "";
      } else if (e.target.value == "First Half") {
        extratoppings.find((v) => v.toppingname === "First Half").toppinglist =
          "";
      } else if (e.target.value == "Second Half") {
        extratoppings.find((v) => v.toppingname === "Second Half").toppinglist =
          "";
      }
    }
    if (e.target.value == "Whole")
      extratoppings.find((v) => v.toppingname === "Whole").checked =
        !extratoppings.find((v) => v.toppingname === "Whole").checked;
    else if (e.target.value == "First Half")
      extratoppings.find((v) => v.toppingname === "First Half").checked =
        !extratoppings.find((v) => v.toppingname === "First Half").checked;
    else if (e.target.value == "Second Half")
      extratoppings.find((v) => v.toppingname === "Second Half").checked =
        !extratoppings.find((v) => v.toppingname === "Second Half").checked;
    setTempstate(!tempstate);
    calculateamout(quantitycount, 0);
  };
  const changeqty = (type) => {
    var wholepizzaamount = 0;
    var firstpizzaamount = 0;
    var secondpizzaamount = 0;

    var qty = quantitycount;
    if (type == "a") {
      setQuantitycount(quantitycount + 1);
      qty++;
    } else {
      setQuantitycount(quantitycount > 1 ? quantitycount - 1 : quantitycount);
      quantitycount > 1 ? qty-- : qty;
    }
    setPizzainfo(pizzainfo);
    reducer();
    calculateamout(qty, 0);
    reducer();
  };
  const addextratoppings = (e, row, index) => {
    if (e.target.checked) {
      let obj = {
        toppingid: row.toppingid,
        toppingname: row.toppingname,
        category: toppingcategroy,
        amount: toppingcategroy === "Whole" ? row.fullprice : row.halfprice,
      };
      addontoppingslistarray.push(obj);
    } else {
      var index = addontoppingslistarray
        .map(function (item) {
          return item.toppngid;
        })
        .indexOf(row.toppngid);
      addontoppingslistarray.splice(index, 1);
    }
    pizzaextratoppings[index].checked = !pizzaextratoppings[index].checked;
  };
  const saveextratoppings = () => {
    var wholepizzatoppings = "";
    var firstpizzatoppings = "";
    var secondpizzatoppings = "";
    var wholepizzaamount = 0;
    var firstpizzaamount = 0;
    var secondpizzaamount = 0;
    if (toppingcategroy == "Whole") {
      var newArray = addontoppingslistarray.filter(function (el) {
        return el.category === "Whole";
      });
      for (let i = 0; i < newArray.length; i++) {
        wholepizzaamount = wholepizzaamount + newArray[i].amount;
        if (i === 0) wholepizzatoppings = newArray[i].toppingname;
        else
          wholepizzatoppings =
            wholepizzatoppings + ", " + newArray[i].toppingname;
      }
      extratoppings.find((v) => v.toppingname === "Whole").toppinglist =
        wholepizzatoppings;
      extratoppings.find((v) => v.toppingname === "Whole").amount =
        wholepizzaamount;
    } else if (toppingcategroy == "First Half") {
      var newArray = addontoppingslistarray.filter(function (el) {
        return el.category === "First Half";
      });
      for (let i = 0; i < newArray.length; i++) {
        firstpizzaamount = firstpizzaamount + newArray[i].amount;
        if (i === 0) firstpizzatoppings = newArray[i].toppingname;
        else
          firstpizzatoppings =
            firstpizzatoppings + ", " + newArray[i].toppingname;
      }
      extratoppings.find((v) => v.toppingname === "First Half").toppinglist =
        firstpizzatoppings;
      extratoppings.find((v) => v.toppingname === "First Half").amount =
        firstpizzaamount;
    } else if (toppingcategroy == "Second Half") {
      var newArray = addontoppingslistarray.filter(function (el) {
        return el.category === "Second Half";
      });
      for (let i = 0; i < newArray.length; i++) {
        secondpizzaamount = secondpizzaamount + newArray[i].amount;
        if (i === 0) secondpizzatoppings = newArray[i].toppingname;
        else
          secondpizzatoppings =
            secondpizzatoppings + ", " + newArray[i].toppingname;
      }
      extratoppings.find((v) => v.toppingname === "Second Half").toppinglist =
        secondpizzatoppings;
      extratoppings.find((v) => v.toppingname === "Second Half").amount =
        secondpizzaamount;
    }

    setPizzainfo(extratoppings);
    reducer();
    setShowtoppings(false);
    calculateamout(quantitycount, 0);
  };
  const canceltoppings = () => {
    setShowtoppings(false);
    if (toppingcategroy == "Whole")
      extratoppings.find((v) => v.toppingname === "Whole").checked = false;
    else if (toppingcategroy == "First Half")
      extratoppings.find((v) => v.toppingname === "First Half").checked = false;
    else if (toppingcategroy == "Second Half")
      extratoppings.find(
        (v) => v.toppingname === "Second Half"
      ).checked = false;
  };
  /*End */
  /*Package items */
  const onclickfun = (rowdata) => {
    setSizeamt(0);
    setToppingamt(0);
    setSelectedamount(0);
    setPizzasize([]);
    setPizzadefaultremove([]);
    setToppingcategory([]);
    // canceltoppings();
    setPizzabasevalue("");
    setPizzaremmovevalue("");
    setPizzainfo([]);
    setSelectedspicy("");
    setDefaultrowdata(rowdata);
    if (rowdata.packageitem > 0) {
      setPackagerowdata(JSON.parse(rowdata.packageitemdetails));
      openpackageinfo(JSON.parse(rowdata.packageitemdetails), rowdata);
    } else {
      loadItemdata(rowdata, 1, 0);
    }
  };
  const openpackageinfo = (data, rowdata) => {
    //debugger;
    setEnablenext(true);
    setPackagcode(rowdata.itemcode);
    setPackagename(rowdata.itemname);
    setPackagedesc(rowdata.description);
    let items = data;
    let max = Math.max(...items.map(({ serialno }) => serialno));

    for (let index = 0; index < data.length; index++) {
      data[index].itemtypelist = JSON.parse(data[index].itemtypelist);
      for (let b = 0; b < data[index].itemtypelist.length; b++) {
        data[index].itemtypelist[b].checked = false;
        data[index].itemtypelist[b].packageval = "";
        data[index].itemtypelist[b].itemtype = data[index].itemtype;
      }
    }

    setSteps(data);
    setSizeamt(parseFloat(rowdata.unitPrice).toFixed(2));
    reducer();
    setViewsteper(true);
  };
  /*end */
  const Additem = (e, index, outerindex, row) => {
    setpost(0);
    setcindex(outerindex);
    for (let a = 0; a < steps[outerindex].itemtypelist.length; a++) {
      steps[outerindex].itemtypelist[a].checked = false;
      steps[outerindex].itemtypelist[a].packageval = "";
    }
    let count = steps[outerindex].itemtypelist.length;
    for (let a = 0; a < steps[outerindex].itemtypelist.length; a++) {
      if (a === index)
        if (e.target.checked) {
          steps[outerindex].itemtypelist[a].checked = true;
          loadItemdata(steps[outerindex].itemtypelist[a], 2, 1);
          refreshfun();
        } else {
          steps[outerindex].itemtypelist[a].checked = false;
          refreshfun();
        }
    }
    var checkedlist = steps[outerindex].itemtypelist.filter(
      (item) => item.checked
    );
    setSteps(steps);
    if (checkedlist.length > 0) setEnablenext(false);
    else setEnablenext(true);

    if (activeStep === 0) {
      for (let i = 0; i < pizzainfo.length; i++) {
        pizzainfo[i].checked = false;
      }
      for (let i = 0; i < extratoppings.length; i++) {
        extratoppings[i].checked = false;
      }
      reducer();

      calculateamout(quantitycount, 1);
    }
  };
  return (
    <div className="dashboard-page">
      <div className="dashboard-header ">
        <Typography
          component={"span"}
          variant="h5"
          noWrap
          sx={{
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            textDecoration: "none",
            color: "white",
            ml: 5,
          }}
        ></Typography>
        <div className="col-6">
          <div className="row">
            <div
              className="col-4"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            ></div>
            <div
              className="col-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Tooltip title="Answer">
                <span>
                  <IconButton
                    aria-label="Example"
                    sx={{
                      float: "right",
                      p: 2,
                      border: "2px solid white",
                      marginTop: "0.2rem",
                      background: "green !important",
                      padding: "10",
                    }}
                    onClick={() => {
                      makecall(1);
                    }}
                    disabled={callbtn}
                  >
                    <RingVolumeOutlinedIcon
                      id="btnincomming"
                      sx={{
                        color: "#fff",
                        fontSize: 30,
                        width: "0.9em",
                        height: "0.9em",
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Hangup">
                <span>
                  <IconButton
                    aria-label="Example"
                    sx={{
                      float: "right",
                      p: 2,
                      border: "2px solid white",
                      marginTop: "0.2rem",
                      background: "red !important",
                      padding: "10",
                    }}
                    onClick={() => {
                      makecall(0);
                    }}
                    disabled={hubtn}
                  >
                    <PhoneDisabledOutlinedIcon
                      sx={{ color: "#fff", fontSize: 27.5 }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
            <div className="col-4">
              <Tooltip title="Click to logout">
                <IconButton
                  sx={{ float: "right", p: 2 }}
                  onClick={handleProfileMenuOpen}
                >
                  <Avatar
                    alt={state.loginreducer.logininfo.data.resultSet.agentname}
                    src="/static/images/avatar/3.jpg"
                  />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div
        className="dashboard-container"
        style={{ width: "100vw", padding: 0 }}
      >
        {ddldata.length > 0
          ? [
              <div
                className="row m-fadeIn"
                style={{ padding: "0.2rem", margin: 0 }}
              >
                <div
                  className="col-md-2"
                  style={{
                    padding: "0px 5px 0px 5px",
                    borderRight: "2px solid orange",
                  }}
                >
                  <Button
                    variant="outlined"
                    className={
                      itemactive == "IOD" ? "currentbtn" : "currentbtnfont"
                    }
                    onClick={() => {
                      if (itemactive == "IOD") {
                        setItemactive("");
                        setItemoftheday(false);
                        setItempromotion(false);
                        setItemdelay(false);
                        refreshfun();
                      } else {
                        setItemactive("IOD");
                        setItemoftheday(true);
                        setItempromotion(false);
                        setItemdelay(false);
                        refreshfun();
                      }
                    }}
                    sx={{ width: "100%", marginTop: "0.3rem" }}
                  >
                    Item of the day
                  </Button>
                  <Button
                    variant="outlined"
                    className={
                      itemactive == "IP" ? "currentbtn" : "currentbtnfont"
                    }
                    onClick={() => {
                      if (itemactive == "IP") {
                        setItemactive("");
                        setItempromotion(false);
                        setItemdelay(false);
                        setItemoftheday(false);
                      } else {
                        setItemactive("IP");
                        setItempromotion(true);
                        setItemdelay(false);
                        setItemoftheday(false);
                      }
                    }}
                    sx={{ width: "100%", marginTop: "0.6rem" }}
                  >
                    Item promotion
                  </Button>
                  <Button
                    variant="outlined"
                    className={
                      itemactive == "ID" ? "currentbtn" : "currentbtnfont"
                    }
                    onClick={() => {
                      if (itemactive == "ID") {
                        setItemactive("");
                        setItempromotion(false);
                        setItemoftheday(false);
                        setItemdelay(false);
                      } else {
                        setItemactive("ID");
                        setItemdelay(true);
                        setItempromotion(false);
                        setItemoftheday(false);
                      }
                    }}
                    sx={{ width: "100%", marginTop: "0.6rem" }}
                  >
                    Delayed items
                  </Button>
                </div>
                <div className="col-md-8">
                  <div className="row ">
                    <div
                      className="col-md-12"
                      style={{ padding: "0 0 0.5rem 0" }}
                    >
                      <ul
                        className="list-group scrollbar"
                        id="style-5"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                          maxHeight: "100%",
                          overflow: "auto",
                        }}
                      >
                        {ddldata.map((name, index) => (
                          <div>
                            <li
                              style={{ float: "left", padding: "0.3rem" }}
                              key={"liddldata" + Math.random()}
                            >
                              {btnactive && name.itemcode == btnid
                                ? [
                                    <Button
                                      variant="outlined"
                                      id={name.itemcode}
                                      onClick={(event) => {
                                        activebutton(
                                          event,
                                          name.itemcode,
                                          index
                                        );
                                      }}
                                      className={
                                        name.ischecked
                                          ? "currentbtn"
                                          : "currentbtnfont"
                                      }
                                      value={name.itemname}
                                    >
                                      {name.itemname}
                                    </Button>,
                                  ]
                                : [
                                    <Button
                                      variant="outlined"
                                      id={name.itemcode}
                                      className={
                                        name.ischecked
                                          ? "currentbtn"
                                          : "currentbtnfont"
                                      }
                                      onClick={(event) => {
                                        activebutton(
                                          event,
                                          name.itemcode,
                                          index
                                        );
                                      }}
                                    >
                                      {name.itemname}
                                    </Button>,
                                  ]}
                            </li>
                          </div>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-2"
                  style={{
                    padding: "0px 5px 0px 5px",
                    borderLeft: "2px solid orange",
                  }}
                >
                  <Button
                    variant="outlined"
                    className={
                      categroyactive == "NV" ? "currentbtn" : "currentbtnfont"
                    }
                    onClick={() => {
                      if (categroyactive == "NV") {
                        setCategoryactive("");
                        setCategory("");
                      } else {
                        setCategoryactive("NV");
                        setCategory("Nonvegetarian");
                      }
                    }}
                    sx={{ width: "100%", marginTop: "0.3rem" }}
                  >
                    Non-veg
                  </Button>
                  <Button
                    variant="outlined"
                    className={
                      categroyactive == "VEGA" ? "currentbtn" : "currentbtnfont"
                    }
                    onClick={() => {
                      if (categroyactive == "VEGA") {
                        setCategoryactive("");
                        setCategory("");
                      } else {
                        setCategoryactive("VEGA");
                        setCategory("Vegan");
                      }
                    }}
                    sx={{ width: "100%", marginTop: "0.6rem" }}
                  >
                    Vegan
                  </Button>
                  <Button
                    variant="outlined"
                    className={
                      categroyactive == "VEG" ? "currentbtn" : "currentbtnfont"
                    }
                    onClick={() => {
                      if (categroyactive == "VEG") {
                        setCategoryactive("");
                        setCategory("");
                      } else {
                        setCategoryactive("VEG");
                        setCategory("Vegetarian");
                      }
                    }}
                    sx={{ width: "100%", marginTop: "0.6rem" }}
                  >
                    Veg
                  </Button>
                </div>
                {/* body */}
                <div className="row m-fadeIn pt-2">
                  <div className="col-md-4 col-lg-4">
                    <input
                      type="text"
                      id="txtsearch"
                      defaultValue={tempval}
                      style={{ display: "none" }}
                    ></input>
                    <Search sx={{ float: "left" }}>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(event) => {
                          setSearchval(event.target.value);
                        }}
                        value={searchval}
                        ref={inputSearchRef}
                      />
                    </Search>
                  </div>
                  <div className="col-md-8 col-lg-8">
                    {/* <Search sx={{ float: "left" }}>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ "aria-label": "search" }}
                                            value={searchval1}
                                            onChange={(event) => {
                                                setSearchval1(event.target.value);
                                            }}
                                        />
                                    </Search> */}
                    {outgoing
                      ? []
                      : [
                          <Tooltip title="Call to restaurant for assistance">
                            <Button
                              id="btnoutgoing"
                              className="currentbtn"
                              disabled={outgoing}
                              endIcon={<PhoneForwardedOutlinedIcon />}
                              sx={{
                                float: "right",
                                backgroundColor: "#ff8404 !important",
                              }}
                              onClick={() => {
                                connectconference();
                              }}
                            >
                              Connect Restaurant
                            </Button>
                          </Tooltip>,
                        ]}
                  </div>
                </div>
                <div
                  className="row m-fadeIn"
                  style={{
                    marginLeft: "0.1rem",
                    padding: "0rem",
                  }}
                >
                  <div className="col-md-4 col-lg-4 ">
                    <TableContainer
                      component={Paper}
                      sx={{
                        height: "calc(100vh - 300px)",
                        overflow: "scroll",
                        height: "calc(100vh - 300px)",
                        overflowX: "hidden",
                      }}
                      id="style-5"
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        size="small"
                      >
                        <TableHead>
                          <TableRow key={"keyfirstheader" + Math.random()}>
                            <TableCell
                              align="right"
                              hidden
                              sx={{
                                backgroundColor: "#fff",
                                border: "1px solid #ff8404",
                                color: "#ff8404",
                              }}
                            >
                              S.No
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                backgroundColor: "#fff",
                                border: "1px solid #ff8404",
                                color: "#ff8404",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align="right"
                              hidden
                              sx={{
                                backgroundColor: "#fff",
                                border: "1px solid #ff8404",
                                borderLeft: "none",
                                color: "#ff8404",
                              }}
                            >
                              Size
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                backgroundColor: "#fff",
                                border: "1px solid #ff8404",
                                borderLeft: "none",
                                color: "#ff8404",
                              }}
                              hidden
                            >
                              {" "}
                              Price
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                backgroundColor: "#fff",
                                border: "1px solid #ff8404",
                                borderLeft: "none",
                                color: "#ff8404",
                              }}
                            >
                              Add
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {orgitemlist
                          .filter((stu) =>
                            stu.itemname
                              .toLowerCase()
                              .includes(searchval.toLowerCase())
                          )
                          .filter((item) =>
                            !ispackage
                              ? item.itemtype
                                  .toLowerCase()
                                  .startsWith(ddlname.toLowerCase())
                              : item.itemtype == ""
                          )
                          .filter((categoryinfo) =>
                            categoryinfo.category
                              .substring(0, 4)
                              .toLowerCase()
                              .includes(category.substring(0, 4).toLowerCase())
                          )
                          .filter((iod) =>
                            itemoftheday
                              ? iod.itemoftheday != 0
                              : iod.itemoftheday == 0 || iod.itemoftheday != 0
                          )
                          .filter((ip) =>
                            itempromotion
                              ? ip.itempromotion != 0
                              : ip.itempromotion == 0 || ip.itempromotion != 0
                          )
                          .filter((id) =>
                            itemdelay
                              ? id.itemdelay != 0
                              : id.itemdelay == 0 || id.itemdelay != 0
                          )
                          .map((row, index) => (
                            <TableBody
                              className={
                                row.isoutofstock == 0
                                  ? row.itemdelay > 0
                                    ? "itemdelay"
                                    : ""
                                  : "outofstock"
                              }
                              key={"originalitemlist" + Math.random() + index}
                            >
                              <TableRow
                                key={"org" + Math.random() + "index" + index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: "none",
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="right"
                                  hidden
                                >
                                  <span> {index + 1}</span>
                                </TableCell>
                                <TableCell align="left">
                                  <Tooltip title={row.description}>
                                    {row.itemdelay == 0 ? (
                                      <span>{row.itemname}</span>
                                    ) : (
                                      <Box>
                                        {row.itemname}
                                        <Typography
                                          component={"span"}
                                          color="text.secondary"
                                          variant="body2"
                                        >
                                          {row.itemdelaymins} - Mins Delay
                                        </Typography>
                                      </Box>
                                    )}
                                  </Tooltip>
                                  {row.audiopath != "" && (
                                    <span style={{ float: "right" }}>
                                      {!play
                                        ? [
                                            <PlayCircleOutlineOutlinedIcon
                                              onClick={() => {
                                                playaudio(1, row.audiopath);
                                              }}
                                            />,
                                          ]
                                        : [
                                            <PauseCircleOutlineOutlinedIcon
                                              onClick={() => {
                                                playaudio(0, row.audiopath);
                                              }}
                                            />,
                                          ]}
                                    </span>
                                  )}
                                  {row.packageitem > 0
                                    ? [
                                        // <IconButton
                                        //     aria-label="expand row"
                                        //     size="small" style={{ 'display': 'none' }}
                                        //     onClick={() => {
                                        //         row.itemcode ==
                                        //             JSON.parse(
                                        //                 row.packageitemdetails
                                        //             )[0].packageitemcode
                                        //             ? [
                                        //                 setOpen(!open),
                                        //                 setModelitem(row.itemcode),
                                        //             ]
                                        //             : [];
                                        //     }}
                                        // >
                                        //     {modelitem == row.itemcode
                                        //         ? open
                                        //             ? [<KeyboardArrowUpIcon />]
                                        //             : [<KeyboardArrowDownIcon />]
                                        //         : [<KeyboardArrowDownIcon />]}
                                        // </IconButton>,
                                      ]
                                    : []}
                                </TableCell>
                                <TableCell align="right" hidden>
                                  {row.sizename}
                                </TableCell>
                                <TableCell align="right" hidden>
                                  {row.unitPrice}
                                </TableCell>
                                <TableCell align="center">
                                  {row.isoutofstock == 0
                                    ? [
                                        <IconButton
                                          color="success"
                                          aria-label="upload picture"
                                          component="label"
                                          onClick={() => {
                                            resttype == "-1"
                                              ? selectobj(row, "A", index)
                                              : onclickfun(row);
                                          }}
                                        >
                                          <AddShoppingCartOutlinedIcon />
                                        </IconButton>,
                                      ]
                                    : [
                                        <Tooltip title="Out of stock">
                                          <IconButton aria-label="Example">
                                            <ProductionQuantityLimitsOutlinedIcon />
                                          </IconButton>
                                        </Tooltip>,
                                      ]}
                                </TableCell>
                              </TableRow>
                              {row.packageitem > 0
                                ? [
                                    // modelitem == row.itemcode
                                    //     ? [<TableBody>
                                    //         <TableRow key={'org1' + Math.random()+index}
                                    //             sx={{
                                    //                 "&:last-child td, &:last-child th":
                                    //                 {
                                    //                     border: "none",
                                    //                 },
                                    //             }}
                                    //         >
                                    //             <TableCell colSpan={2}>
                                    //                 <Collapse
                                    //                     in={open}
                                    //                     timeout="auto"
                                    //                     unmountOnExit
                                    //                 >
                                    //                     <Table
                                    //                         size="small"
                                    //                         aria-label="purchases"
                                    //                     >
                                    //                         <TableHead>
                                    //                             <TableRow key={'orgsub1' + Math.random()+'index'+index}>
                                    //                                 <TableCell hidden>
                                    //                                     Sl.No
                                    //                                 </TableCell>
                                    //                                 <TableCell>
                                    //                                     Name
                                    //                                 </TableCell>
                                    //                                 <TableCell>
                                    //                                     Size
                                    //                                 </TableCell>
                                    //                                 <TableCell align="right">
                                    //                                     Qty
                                    //                                 </TableCell>
                                    //                             </TableRow>
                                    //                         </TableHead>
                                    //                         <TableBody>
                                    //                             {JSON.parse(
                                    //                                 row.packageitemdetails
                                    //                             ).map((rowdata, index) => (
                                    //                                 <TableRow
                                    //                                     key={'Math'+Math.random()+index+'list'+rowdata.serialno}
                                    //                                 >
                                    //                                     <TableCell
                                    //                                         component="th"
                                    //                                         scope="row"
                                    //                                         hidden
                                    //                                     >
                                    //                                         {rowdata.serialno}
                                    //                                     </TableCell>
                                    //                                     <TableCell>
                                    //                                         {rowdata.itemname}
                                    //                                     </TableCell>
                                    //                                     <TableCell>
                                    //                                         {rowdata.sizename}
                                    //                                     </TableCell>
                                    //                                     <TableCell align="right">
                                    //                                         {rowdata.qty}
                                    //                                     </TableCell>
                                    //                                 </TableRow>
                                    //                             ))}
                                    //                         </TableBody>
                                    //                     </Table>
                                    //                 </Collapse>
                                    //             </TableCell>
                                    //         </TableRow></TableBody>,
                                    // ]
                                    // : [],
                                  ]
                                : []}
                            </TableBody>
                          ))}
                        {orgitemlist.length === 0
                          ? [
                              <TableBody>
                                <TableRow
                                  key={"orgIorgitemlist" + Math.random()}
                                >
                                  <TableCell
                                    colSpan={2}
                                    sx={{ textAlign: "center" }}
                                  >
                                    No Records Found
                                  </TableCell>
                                </TableRow>
                              </TableBody>,
                            ]
                          : []}
                      </Table>
                    </TableContainer>
                  </div>
                  <div className="col-md-8 col-lg-8">
                    <TableContainer
                      component={Paper}
                      sx={{
                        height: "calc(100vh - 300px)",
                        overflow: "scroll",
                        height: "calc(100vh - 300px)",
                        overflowX: "hidden",
                      }}
                      id="style-5"
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        size="small"
                      >
                        <TableHead>
                          <TableRow key={"selecteditemheader" + Math.random()}>
                            <TableCell
                              align="right"
                              sx={{
                                backgroundColor: "#fff",
                                border: "1px solid #ff8404",
                                color: "#ff8404",
                              }}
                            >
                              S.No
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                backgroundColor: "#fff",
                                border: "1px solid #ff8404",
                                borderLeft: "none",
                                color: "#ff8404",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                backgroundColor: "#fff",
                                border: "1px solid #ff8404",
                                borderLeft: "none",
                                color: "#ff8404",
                              }}
                            >
                              {" "}
                              Size
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                backgroundColor: "#fff",
                                color: "#ff8404",
                                border: "1px solid #ff8404",
                                borderLeft: "none",
                              }}
                            >
                              {" "}
                              Quantity
                            </TableCell>
                            {/* <TableCell
                                                        align="right"
                                                        sx={{
                                                            backgroundColor: "#fff",
                                                            border: "1px solid #ff8404",
                                                            borderLeft: "none",
                                                            color: "#ff8404",
                                                        }}
                                                    >
                                                        Spicy
                                                    </TableCell> */}
                            <TableCell
                              align="right"
                              sx={{
                                backgroundColor: "#fff",
                                border: "1px solid #ff8404",
                                borderLeft: "none",
                                color: "#ff8404",
                              }}
                            >
                              {" "}
                              Price
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                backgroundColor: "#fff",
                                color: "#ff8404",
                                border: "1px solid #ff8404",
                                borderLeft: "none",
                              }}
                            >
                              Remove
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {stateitemlist
                          .filter((stu) =>
                            stu.itemname
                              .toLowerCase()
                              .includes(searchval1.toLowerCase())
                          )
                          .map((row, index) => (
                            <TableBody
                              key={"selecteditems" + Math.random() + index}
                            >
                              <TableRow
                                key={"sub" + Math.random()}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: "none",
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="right"
                                  width="1rem"
                                >
                                  {index + 1}
                                  <input
                                    type="text"
                                    defaultValue={tempval1}
                                    style={{ display: "none" }}
                                  ></input>
                                </TableCell>

                                <TableCell align="left">
                                  <div>
                                    <Tooltip title={row.description}>
                                      <span>{row.itemname}</span>
                                    </Tooltip>

                                    {row.packageitem > 0
                                      ? [
                                          // <IconButton
                                          //     aria-label="expand row"
                                          //     size="small"
                                          //     onClick={() => {
                                          //         row.itemcode ==
                                          //             JSON.parse(
                                          //                 row.packageitemdetails
                                          //             )[0].packageitemcode
                                          //             ? [
                                          //                 setOpen1(!open1),
                                          //                 setModelitem1(row.itemcode),
                                          //             ]
                                          //             : [];
                                          //     }}
                                          // >
                                          //     {modelitem1 == row.itemcode
                                          //         ? open1
                                          //             ? [<KeyboardArrowUpIcon />]
                                          //             : [<KeyboardArrowDownIcon />]
                                          //         : [<KeyboardArrowDownIcon />]}
                                          // </IconButton>,
                                        ]
                                      : []}
                                  </div>
                                </TableCell>
                                <TableCell align="left">
                                  {row.sizename}
                                </TableCell>
                                <TableCell align="right" width="1rem">
                                  <ButtonGroup>
                                    <IconButton
                                      color="error"
                                      aria-label="reduce"
                                      component="label"
                                      size="small"
                                      onClick={() => {
                                        changeCount(row, "D", index);
                                      }}
                                    >
                                      <RemoveIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                      aria-label="count"
                                      component="label"
                                      size="small"
                                    >
                                      {row.itemcount}
                                    </IconButton>
                                    <IconButton
                                      color="success"
                                      aria-label="increase"
                                      component="label"
                                      size="small"
                                      onClick={() => {
                                        changeCount(row, "I", index);
                                      }}
                                    >
                                      <AddIcon fontSize="small" />
                                    </IconButton>
                                  </ButtonGroup>

                                  {/* {row.itemcount} */}
                                </TableCell>
                                {/* <TableCell align="right" width="1rem">
                                                                <ButtonGroup>
                                                                    <IconButton
                                                                        color="error"
                                                                        aria-label="reduce"
                                                                        component="label"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            changespicy(row, "D", index);
                                                                        }}
                                                                    >
                                                                        <RemoveIcon fontSize="small" />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        aria-label="count"
                                                                        component="label"
                                                                        size="small"
                                                                    >
                                                                        {row.spicy}
                                                                    </IconButton>

                                                                    <IconButton
                                                                        color="success"
                                                                        aria-label="increase"
                                                                        component="label"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            changespicy(row, "I", index);
                                                                        }}
                                                                    >
                                                                        <AddIcon fontSize="small" />
                                                                    </IconButton>
                                                                </ButtonGroup>
                                                            </TableCell> */}
                                <TableCell align="right">
                                  {row.itemprice}
                                </TableCell>

                                <TableCell align="center" width="1rem">
                                  <IconButton
                                    color="error"
                                    aria-label="upload picture"
                                    component="label"
                                    onClick={() => {
                                      selectobj(row, "R", index);
                                    }}
                                  >
                                    <RemoveShoppingCartOutlinedIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                              {row.packageitem > 0
                                ? [
                                    modelitem1 == row.itemcode
                                      ? [
                                          // <TableBody>
                                          //     <TableRow key={'org_iu1' + Math.random()}
                                          //         sx={{
                                          //             "&:last-child td, &:last-child th":
                                          //             {
                                          //                 border: "none",
                                          //             },
                                          //         }}
                                          //     >
                                          //         <TableCell colSpan={7}>
                                          //             <Collapse
                                          //                 in={open1}
                                          //                 timeout="auto"
                                          //                 unmountOnExit
                                          //             >
                                          //                 <Table
                                          //                     size="small"
                                          //                     aria-label="purchases"
                                          //                 >
                                          //                     <TableHead>
                                          //                         <TableRow key={'packmainheader' + Math.random()}>
                                          //                             <TableCell>
                                          //                                 Sl.No
                                          //                             </TableCell>
                                          //                             <TableCell>
                                          //                                 Name
                                          //                             </TableCell>
                                          //                             <TableCell>
                                          //                                 Size
                                          //                             </TableCell>
                                          //                             <TableCell align="right">
                                          //                                 Qty
                                          //                             </TableCell>
                                          //                         </TableRow>
                                          //                     </TableHead>
                                          //                     <TableBody>
                                          //                         {JSON.parse(
                                          //                             row.packageitemdetails
                                          //                         ).map((rowdata, index) => (
                                          //                             <TableRow
                                          //                                 key={'serial' + Math.random()+'-'+rowdata.serialno}
                                          //                             >
                                          //                                 <TableCell
                                          //                                     component="th"
                                          //                                     scope="row"
                                          //                                 >
                                          //                                     {rowdata.serialno}
                                          //                                 </TableCell>
                                          //                                 <TableCell>
                                          //                                     {rowdata.itemname}
                                          //                                 </TableCell>
                                          //                                 <TableCell>
                                          //                                     {rowdata.sizename}
                                          //                                 </TableCell>
                                          //                                 <TableCell align="right">
                                          //                                     {rowdata.qty}
                                          //                                 </TableCell>
                                          //                             </TableRow>
                                          //                         ))}
                                          //                     </TableBody>
                                          //                 </Table>
                                          //             </Collapse>
                                          //         </TableCell>
                                          //     </TableRow></TableBody>,
                                        ]
                                      : [],
                                  ]
                                : []}
                              <TableRow
                                sx={{ border: "none" }}
                                key={"orgitemlistss" + Math.random()}
                              >
                                <TableCell></TableCell>
                                <TableCell colSpan={6}>
                                  <span>{row.remarks}</span>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                        {orgitemlist.length > 0 && stateitemlist.length > 0
                          ? [
                              <TableBody>
                                <TableRow key={"morgitemlist" + Math.random()}>
                                  <TableCell colSpan={3}>Total</TableCell>
                                  <TableCell align="right">
                                    <ButtonGroup>
                                      <IconButton
                                        color="error"
                                        aria-label="reduce"
                                        component="label"
                                        size="small"
                                        sx={{ color: "transparent" }}
                                      >
                                        <RemoveIcon fontSize="small" />
                                      </IconButton>
                                      <IconButton
                                        aria-label="count"
                                        component="label"
                                        size="small"
                                      >
                                        {/* {totalitems} */}
                                      </IconButton>

                                      <IconButton
                                        color="success"
                                        aria-label="increase"
                                        component="label"
                                        size="small"
                                        sx={{ color: "transparent" }}
                                      >
                                        <AddIcon fontSize="small" />
                                      </IconButton>
                                    </ButtonGroup>
                                  </TableCell>
                                  <TableCell align="right"></TableCell>
                                  <TableCell align="right">
                                    <b> {totalamount}</b>
                                    {/* {amount} */}
                                  </TableCell>
                                  <TableCell align="right"></TableCell>
                                </TableRow>
                              </TableBody>,
                            ]
                          : [
                              <TableBody>
                                <TableRow key={"No" + Math.random()}>
                                  <TableCell
                                    colSpan={7}
                                    sx={{ textAlign: "center" }}
                                  >
                                    No Items Added
                                  </TableCell>
                                </TableRow>
                              </TableBody>,
                            ]}
                      </Table>
                    </TableContainer>
                  </div>
                </div>
                <div className="row footer">
                  <div className="col-md-12 col-lg-12">
                    {orgitemlist.length > 0 ? (
                      <Button
                        variant="outlined"
                        style={{ float: "right" }}
                        onClick={() => {
                          previewitems();
                        }}
                      >
                        Place orders
                      </Button>
                    ) : (
                      []
                    )}
                  </div>
                </div>
              </div>,
            ]
          : [
              <div className="m-fadeIn cendiv">
                <b>Waiting for a call</b>
                {/* <img
                                className="rot"
                                alt="logo"
                                src={waiting}
                                style={{ height: "100px" }}
                            /> */}
              </div>,
            ]}

        <Dialog
          fullScreen
          open={opensetting}
          onClose={closeExpertsettings}
          TransitionComponent={Transition}
          sx={{ left: "70%", width: "50%" }}
        >
          <Expertsettings handleClose={closeExpertsettings} />
        </Dialog>
        <Dialog
          fullScreen
          open={openordersetting}
          onClose={() => {
            setOpenordersetting(false);
          }}
          TransitionComponent={Transition}
          sx={{ left: "70%", width: "50%" }}
        >
          <div style={{ overflow: "hidden" }}>
            <AppBar
              sx={{
                position: "fixed",
                top: "auto",
                bottom: 0,
                backgroundColor: "#fff",
                left: "70%",
                width: "30%",
              }}
            >
              <Toolbar>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Stack direction="row" spacing={2}>
                  <FormControl
                    sx={{
                      minWidth: 120,
                    }}
                    size="small"
                  >
                    <Button
                      color="error"
                      variant="outlined"
                      onClick={() => {
                        setOpenordersetting(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </FormControl>
                  <FormControl
                    sx={{
                      minWidth: 120,
                    }}
                    size="small"
                  >
                    {post === 0 ? (
                      <Button
                        id="btnsubmit"
                        color="success"
                        variant="outlined"
                        className="call-bttn"
                        onClick={() => {
                          saveorder();
                        }}
                      >
                        Submit
                      </Button>
                    ) : (
                      []
                    )}
                  </FormControl>
                </Stack>
              </Toolbar>
            </AppBar>
            <AppBar
              sx={{
                position: "fixed",
                top: 0,
                bottom: "auto",
                backgroundColor: "#01c0c8",
                left: "70%",
                width: "30%",
              }}
            >
              <Toolbar>
                <Typography
                  component={"span"}
                  sx={{
                    ml: 2,
                    flex: 1,
                    fontSize: "1.25rem",
                    fontWeight: "500",
                  }}
                >
                  Customer Info
                </Typography>
                <Box sx={{ flexGrow: 1 }}></Box>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    setOpenordersetting(false);
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Box
              sx={{
                display: "flex",
                "& > :not(style)": {
                  mt: 8,
                  ml: 5,
                  mr: 5,
                  width: "50%",
                  height: "100%",
                },
              }}
            >
              <Paper elevation={0}>
                <Stack direction="row" spacing={1} sx={{ mt: 3, ml: 2 }}>
                  {custname === ""
                    ? [
                        <TextField
                          sx={{ minWidth: 300 }}
                          size="small"
                          required
                          label="Name"
                          variant="outlined"
                          autoComplete="off"
                          value={name}
                          onChange={(event) => {
                            setCustname(event.target.value);
                          }}
                        />,
                      ]
                    : [
                        <TextField
                          sx={{ minWidth: 300 }}
                          size="small"
                          required
                          label="Name"
                          variant="outlined"
                          autoComplete="off"
                          value={custname}
                          onChange={(event) => {
                            setCustname(event.target.value);
                          }}
                        />,
                      ]}
                </Stack>
                <Stack direction="row" spacing={0} sx={{ mt: 2, ml: 2 }}>
                  <TextField
                    sx={{ minWidth: 300 }}
                    size="small"
                    required
                    label="Mobile"
                    variant="outlined"
                    autoComplete="off"
                    value={mobile}
                    onChange={(event) => {
                      setMobile(event.target.value);
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={0} sx={{ mt: 2, ml: 2 }}>
                  <select
                    id="ddlqueue"
                    name="ddlqueue"
                    value={queue}
                    onChange={(e) => setQueue(e.target.value)}
                  >
                    <option value="101">English</option>
                    <option value="103">Hindi</option>
                    <option value="104">Malaiyalam</option>
                    <option value="105">Telugu</option>
                    <option value="102">Tamil</option>
                  </select>
                </Stack>
                <Stack direction="row" spacing={0} sx={{ mt: 2, ml: 2 }}>
                  <TextField
                    sx={{ minWidth: 300 }}
                    size="small"
                    required
                    label="Order Note"
                    variant="outlined"
                    autoComplete="off"
                    value={note}
                    onChange={(event) => {
                      setNote(event.target.value);
                    }}
                  />
                </Stack>
              </Paper>
            </Box>
          </div>
        </Dialog>

        <Dialog
          open={viewsteper}
          TransitionComponent={MenuTransition}
          keepMounted
          // onClose={() => {
          //     setViewsteper(false);
          //     handleReset();
          // }}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle
            sx={{
              backgroundColor: "#01c0c8",
              color: "#fff",
              padding: "0.5rem 2rem",
            }}
          >
            {packagename}
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                setViewitem(false);
                setViewsteper(false);
                handleReset();
              }}
              aria-label="close"
              sx={{ float: "right", fontStyle: "bold" }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ width: "95vw", height: "50vh" }}>
            <div className="row pt-1">
              <div className="col-md-12 pt-1 pb-1">
                <span>{packagedesc}</span>
              </div>
            </div>
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, stepindex) => {
                  const stepProps = {};
                  const labelProps = {};

                  if (isStepSkipped(stepindex)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={"label" + Math.random()} {...stepProps}>
                      <StepLabel {...labelProps} key={"Step" + Math.random()}>
                        {label.itemtype}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Box
                    sx={{
                      height: "calc(100vh - 510px)",
                      overflow: "scroll",
                      overflowX: "hidden",
                    }}
                    id="style-5"
                  >
                    <Typography component={"span"}>
                      {selecteditems.map((item, index1) => (
                        <List
                          sx={{
                            width: "100%",
                            maxWidth: 360,
                            bgcolor: "background.paper",
                          }}
                          subheader={
                            <ListSubheader>{item.itemtype}</ListSubheader>
                          }
                          key={"selecteditems" + Math.random() + index1}
                        >
                          <ListItem sx={{ padding: "0em 0em 0em 3em" }}>
                            <ListItemIcon>
                              <FastfoodIcon />
                            </ListItemIcon>
                            <ListItemText
                              id="switch-list-label-wifi"
                              primary={item.itemname}
                            />
                          </ListItem>
                        </List>
                      ))}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        pt: 2,
                        textAlign: "right",
                        position: "absolute",
                        bottom: "4.7rem",
                        right: "1.3rem",
                      }}
                    >
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box
                    sx={{
                      height: "calc(100vh - 534px)",
                      overflow: "scroll",
                      overflowX: "hidden",
                    }}
                    id="style-5"
                  >
                    {steps.map((label, outerindex) =>
                      label.itemtypelist.map(
                        (row, index2) =>
                          label.serialno == activeStep + 1 && (
                            <Typography
                              component={"span"}
                              sx={{
                                mt: 2,
                                mb: 1,
                              }}
                              key={"itemtypelist" + Math.random() + index2}
                            >
                              <div
                                className="form-check"
                                style={{
                                  paddingTop: "0.3rem",
                                  paddingBottom: "0.3rem",
                                  marginLeft: "2rem",
                                }}
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="toppincb"
                                  id={"xt" + row.itemid}
                                  value={row.itemname}
                                  checked={row.checked}
                                  onChange={(e) => {
                                    Additem(e, index2, outerindex, row);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"xt" + row.itemid}
                                >
                                  {row.itemname}
                                </label>

                                {row.packageval}
                              </div>
                            </Typography>
                          )
                      )
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pt: 2,
                      bottom: 0,
                    }}
                  >
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />

                    <Button onClick={handleNext} disabled={enablenext}>
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              //   backgroundColor: "#01c0c8",
              color: "#01c0c8",
              borderTop: "1px dotted #01c0c8",
            }}
          >
            <Button
              onClick={() => {
                setViewsteper(false);
                handleReset();
              }}
              sx={{
                color: "#01c0c8",
                border: "1px solid #01c0c8",
              }}
            >
              Close
            </Button>
            <Button sx={{ width: "25%" }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow key={"custometoppingheader" + Math.random()}>
                    <TableCell
                      align="right"
                      sx={{
                        backgroundColor: "#fff",
                        border: "0px solid #ff8404",
                        color: "#ff8404",
                      }}
                    >
                      Amount
                      <br />
                      {sizeamt}
                    </TableCell>

                    <TableCell
                      align="right"
                      sx={{
                        backgroundColor: "#fff",
                        border: "0px solid #ff8404",
                        color: "#ff8404",
                      }}
                    >
                      Topping
                      <br />
                      {toppingamt}
                    </TableCell>

                    <TableCell
                      align="right"
                      sx={{
                        backgroundColor: "#fff",
                        border: "0px solid #ff8404",
                        color: "#ff8404",
                      }}
                    >
                      Total
                      <br />
                      {selectedamount}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Button>
            {activeStep === steps.length ? (
              <Button
                onClick={() => {
                  setViewsteper(false);
                  selectobj(itemdata, "A", 1);
                  handleReset();
                }}
                sx={{
                  color: "#01c0c8",
                  border: "1px solid #01c0c8",
                }}
              >
                Add to cart
              </Button>
            ) : (
              ""
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          open={viewItem}
          TransitionComponent={MenuTransition}
          keepMounted
          // onClose={() => {
          //     setViewitem(false);
          // }}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="lg"
          fullWidth={true}
        >
          <DialogTitle
            sx={{
              backgroundColor: "#01c0c8",
              color: "#fff",
              padding: "0.5rem 2rem",
            }}
          >
            {iteminfo.itemname}
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                setViewitem(false);
              }}
              aria-label="close"
              sx={{ float: "right", fontStyle: "bold" }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="row pt-1">
              <div className="col-md-12 pt-1 pb-1">
                {isownpizza ? (
                  <span>Create own pizza - {iteminfo.itemdescription}</span>
                ) : (
                  <span>{iteminfo.itemdescription}</span>
                )}
              </div>
              {/* <div
                                className="col-md-6"
                                id="style-5"
                                style={{
                                    height: "calc(100vh - 241px)",
                                    overflow: "auto",
                                    overflowX: "hidden",
                                }}
                            >
                                <DialogContentText
                                    id="alert-dialog-slide-description"
                                    sx={{
                                        border: " 0.5px solid rgb(124, 124, 125)",
                                        boxShadow: "rgb(124 124 125) 0px 0px 1px 1px",
                                    }}
                                >
                                    {isimage ? (
                                        <ImageListItem>
                                            <img
                                                src={
                                                    iteminfo.imagepath + "??w=248&fit=crop&auto=format"
                                                }
                                                srcSet={
                                                    iteminfo.imagepath +
                                                    "??w=248&fit=crop&auto=format&dpr=2 2x"
                                                }
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={itemdata.itemname}
                                                subtitle={itemdata.itemtype}
                                            />
                                        </ImageListItem>
                                    ) : (
                                        <ImageListItem>
                                            <img
                                                src={noimage}
                                                srcSet={noimage}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={itemdata.itemname}
                                                subtitle={itemdata.itemtype}
                                            />
                                        </ImageListItem>
                                    )}
                                </DialogContentText>
                            </div> */}
              <div className="col-md-12">
                <Box>
                  <div className="row">
                    <div className="col-md-2 text-center"></div>
                    {showtoppings
                      ? [
                          <div
                            className="col-md-9 pt-2"
                            id="style-5"
                            style={{
                              height: "calc(100vh - 241px)",
                              overflow: "auto",
                              overflowX: "hidden",
                              border: "0.5px solid #7c7c7d",
                              boxShadow: "0px 0px 1px 1px #7c7c7d",
                              position: "relative",
                            }}
                          >
                            <div
                              style={{ marginLeft: "1rem" }}
                              className="m-fadeIn"
                            >
                              <h6>
                                <Tooltip title="Back">
                                  <IconButton
                                    color="warning"
                                    aria-label="upload picture"
                                    component="label"
                                    onClick={() => {
                                      canceltoppings();
                                    }}
                                    style={{
                                      position: "sticky",
                                      top: "0px",
                                      backgroundColor: "#fff",
                                    }}
                                  >
                                    <ArrowBackOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                                Select topping for {toppingcategroy}
                              </h6>
                              <div
                                style={{
                                  overflow: "scroll",
                                  height: "calc(100vh - 300px)",
                                }}
                              >
                                {pizzaextratoppings.map((item, etindex) => (
                                  <div
                                    className="form-check"
                                    style={{
                                      paddingTop: "0.5rem",
                                      paddingBottom: "0.5rem",
                                      marginLeft: "2rem",
                                    }}
                                    key={
                                      "pizzaextratoppings" +
                                      Math.random() +
                                      etindex
                                    }
                                  >
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={"et" + item.toppingid}
                                      value={item.toppingname}
                                      onChange={(e) => {
                                        addextratoppings(e, item, etindex);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={"et" + item.toppingid}
                                    >
                                      {item.toppingname}
                                    </label>
                                    <span style={{ float: "right" }}>
                                      {" "}
                                      {toppingcategroy === "Whole"
                                        ? item.fullprice
                                        : item.halfprice}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div
                              style={{
                                textAlign: "right",
                                position: "sticky",
                                bottom: " 0px",
                                right: 0,
                                backgroundColor: "#fff",
                              }}
                            >
                              <Button
                                onClick={() => {
                                  canceltoppings();
                                }}
                                sx={{
                                  color: "#01c0c8",
                                  float: "left",
                                }}
                              >
                                Close
                              </Button>
                              &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                              &nbsp; &nbsp;&nbsp;&nbsp;
                              <Button
                                onClick={() => {
                                  saveextratoppings();
                                }}
                                sx={{
                                  color: "#01c0c8",
                                }}
                              >
                                Save
                              </Button>
                            </div>
                          </div>,
                        ]
                      : [
                          <div
                            className="col-md-9"
                            id="style-5"
                            style={{
                              height: "calc(100vh - 241px)",
                              overflow: "auto",
                              overflowX: "hidden",
                              border: "0.5px solid #7c7c7d",
                              boxShadow: "0px 0px 1px 1px #7c7c7d",
                            }}
                          >
                            <div className="col-md-12 p-1">
                              <h6>Size selection</h6>

                              {pizzasize.map((item, sizeindex) => (
                                <div
                                  className="form-check"
                                  style={{
                                    paddingTop: "0.5rem",
                                    paddingBottom: "0.5rem",
                                    marginLeft: "2rem",
                                    //   borderBottom: "1px solid #d6dade !important",
                                  }}
                                  key={"size" + Math.random()}
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="baseradio"
                                    id={"b" + item.sizeid}
                                    value={item.sizeid}
                                    onChange={(e) => {
                                      setPizzasizevalue(item.sizename);
                                      for (
                                        let si = 0;
                                        si < pizzasize.length;
                                        si++
                                      ) {
                                        pizzasize[si].checked = false;
                                      }
                                      pizzasize[sizeindex].checked =
                                        !pizzasize[sizeindex].checked;
                                      calculateamout(quantitycount, 0);
                                      refreshfun();
                                    }}
                                    checked={item.checked}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"b" + item.sizeid}
                                  >
                                    {item.sizename}
                                  </label>
                                  <span style={{ float: "right" }}>
                                    {" "}
                                    {modeltype != 2 ? item.price : []}
                                    {/* {item.price} */}
                                  </span>
                                </div>
                              ))}
                            </div>
                            <hr
                              className={
                                baseview ? "showdiv solid" : "hidediv solid"
                              }
                            ></hr>
                            <div
                              className={
                                baseview
                                  ? "showdiv col-md-12 p-1"
                                  : "hidediv col-md-12 p-1"
                              }
                            >
                              {pizzabase.lenght > 0 ? <h6>Base</h6> : []}
                              {pizzabase.map((item, baseindex) => (
                                <div
                                  className="form-check"
                                  style={{
                                    paddingTop: "0.5rem",
                                    paddingBottom: "0.5rem",
                                    marginLeft: "2rem",
                                    //   borderBottom: "1px solid #d6dade !important",
                                  }}
                                  key={"pizzabase" + Math.random() + baseindex}
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="basecheckbox"
                                    id={"b" + item.baseid}
                                    value={item.basename}
                                    onChange={(event) => {
                                      selectremovebase(event, baseindex);
                                    }}
                                    checked={item.checked}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"b" + item.baseid}
                                  >
                                    {item.basename}
                                  </label>
                                </div>
                              ))}
                            </div>
                            <hr
                              className={
                                defaulttoppingview
                                  ? "showdiv solid"
                                  : "hidediv solid"
                              }
                            ></hr>
                            <div
                              className={
                                defaulttoppingview
                                  ? "showdiv col-md-12 p-1"
                                  : "hidediv col-md-12 p-1"
                              }
                            >
                              {pizzadefaultremove.lenght > 0 ? (
                                <h6>Default toppings</h6>
                              ) : (
                                []
                              )}

                              {/* [{defaultremovecount}] */}
                              {pizzadefaultremove.map((item, dtindex) => (
                                <div
                                  key={Math.random()}
                                  className="form-check"
                                  style={{
                                    paddingTop: "0.5rem",
                                    paddingBottom: "0.5rem",
                                    marginLeft: "2rem",
                                  }}
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="sizecheckbox"
                                    id={"r" + item.toppingid}
                                    value={item.toppingname}
                                    disabled={removecountenable}
                                    onChange={(event) => {
                                      selectremoveoption(event, dtindex);
                                    }}
                                    checked={item.checked}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"r" + item.baseid}
                                  >
                                    No {item.toppingname}
                                  </label>
                                </div>
                              ))}
                            </div>
                            <hr
                              className={
                                toppingview ? "showdiv solid" : "hidediv solid"
                              }
                            ></hr>
                            <div
                              className={
                                toppingview
                                  ? "showdiv col-md-12 p-1"
                                  : "hidediv col-md-12 p-1"
                              }
                            >
                              <span>
                                <h6>Add on toppings</h6>
                                {extratoppings.map((item, atindex) => (
                                  <div
                                    key={Math.random()}
                                    className="form-check"
                                    style={{
                                      paddingTop: "0.5rem",
                                      paddingBottom: "0.5rem",
                                      marginLeft: "2rem",
                                    }}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="toppincb"
                                      id={"xt" + item.toppingid}
                                      value={item.toppingname}
                                      checked={item.checked}
                                      onChange={(e) => {
                                        openaddon(e);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={"xt" + item.toppingid}
                                    >
                                      {item.toppingname}
                                      <br />
                                      <span
                                        style={{
                                          fontSize: "0.625rem",
                                          color: "rgb(164, 173, 182)",
                                        }}
                                      >
                                        {item.toppinglist}&nbsp;&nbsp;&nbsp;
                                        {item.toppinglist != ""
                                          ? " " + item.amount
                                          : ""}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              </span>
                            </div>
                            <hr className="solid"></hr>

                            {/* <div className="col-md-12 p-1 mb-2">
                                                        <h6>Special instruction</h6>

                                                        <textarea
                                                            className="form-control"
                                                            id="exampleFormControlTextarea1"
                                                            rows="3"
                                                            value={customerinstruction}
                                                            onChange={(e) => {
                                                                setCustomerinstruction(e.target.value);
                                                            }}
                                                        ></textarea>
                                                    </div>
                                                    <hr className="showdiv"></hr> */}
                            <div
                              className={
                                viewsteper
                                  ? "hidediv col-md-12 p-1"
                                  : "showdiv col-md-12 p-1"
                              }
                            >
                              <h6>Quantity selection</h6>

                              <div
                                className="form-check"
                                style={{
                                  paddingTop: "0.5rem",
                                  paddingBottom: "0.5rem",
                                  marginLeft: "2rem",
                                }}
                              >
                                <ButtonGroup>
                                  <IconButton
                                    color="error"
                                    aria-label="reduce"
                                    component="label"
                                    size="small"
                                    onClick={() => {
                                      changeqty("r");
                                    }}
                                  >
                                    <RemoveIcon fontSize="small" />
                                  </IconButton>
                                  <IconButton
                                    aria-label="count"
                                    component="label"
                                    size="small"
                                  >
                                    {quantitycount}
                                  </IconButton>

                                  <IconButton
                                    color="success"
                                    aria-label="increase"
                                    component="label"
                                    size="small"
                                    onClick={() => {
                                      changeqty("a");
                                    }}
                                  >
                                    <AddIcon fontSize="small" />
                                  </IconButton>
                                </ButtonGroup>
                              </div>
                            </div>
                            <hr
                              className={
                                viewsteper ? "hidediv solid" : "showdiv solid"
                              }
                            ></hr>

                            <div className="col-md-12 p-1">
                              {spicyinfo.length > 0 ? (
                                <h6>Spicy selection</h6>
                              ) : (
                                []
                              )}

                              <div
                                className="form-check"
                                style={{
                                  paddingTop: "0.5rem",
                                  paddingBottom: "0.5rem",
                                  marginLeft: "2rem",
                                }}
                              >
                                {spicyinfo.length > 0
                                  ? [
                                      spicyinfo.map((item, sizeindex) => (
                                        <div
                                          className="form-check"
                                          style={{
                                            paddingTop: "0.5rem",
                                            paddingBottom: "0.5rem",
                                            marginLeft: "2rem",
                                          }}
                                          key={"spicy:" + Math.random()}
                                        >
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="spicyradio"
                                            id={"sp" + item.spicyid}
                                            value={item.spicyid}
                                            onChange={(e) => {
                                              setSelectedspicy(item.spicyname);
                                              setSpicycount(1);

                                              for (
                                                let si = 0;
                                                si < spicyinfo.length;
                                                si++
                                              ) {
                                                spicyinfo[si].checked = false;
                                              }
                                              spicyinfo[sizeindex].checked =
                                                !spicyinfo[sizeindex].checked;
                                            }}
                                            checked={item.checked}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={"b" + item.spicyid}
                                          >
                                            {item.spicyname}
                                          </label>
                                        </div>
                                      )),
                                    ]
                                  : //    <ButtonGroup>
                                    //         <IconButton
                                    //             color="error"
                                    //             aria-label="reduce"
                                    //             component="label"
                                    //             size="small"
                                    //             onClick={() => {
                                    //                 setSpicycount(
                                    //                     spicycount > 0
                                    //                         ? spicycount - 1
                                    //                         : spicycount
                                    //                 );
                                    //             }}
                                    //         >
                                    //             <RemoveIcon fontSize="small" />
                                    //         </IconButton>
                                    //         <IconButton
                                    //             aria-label="count"
                                    //             component="label"
                                    //             size="small"
                                    //         >
                                    //             {spicycount}
                                    //         </IconButton>

                                    //         <IconButton
                                    //             color="success"
                                    //             aria-label="increase"
                                    //             component="label"
                                    //             size="small"
                                    //             onClick={() => {
                                    //                 setSpicycount(spicycount + 1);
                                    //             }}
                                    //         >
                                    //             <AddIcon fontSize="small" />
                                    //         </IconButton>
                                    //     </ButtonGroup>

                                    []}
                              </div>
                            </div>
                            <div className="col-md-12 p-1 mb-2">
                              <h6>Special instruction</h6>

                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                value={customerinstruction}
                                onChange={(e) => {
                                  setCustomerinstruction(e.target.value);
                                }}
                              ></textarea>
                            </div>
                            <hr className="showdiv"></hr>
                          </div>,
                        ]}
                    <div className="col-md-3 text-center"></div>
                  </div>
                </Box>
              </div>
            </div>
          </DialogContent>
          <DialogActions
            sx={{
              color: "#01c0c8",
              borderTop: "1px dotted #01c0c8",
            }}
          >
            {" "}
            {!showtoppings ? (
              <Button
                onClick={() => {
                  setViewitem(false);
                  resetwindow();
                }}
                sx={{
                  color: "#01c0c8",
                  border: "1px solid #01c0c8",
                }}
              >
                Close
              </Button>
            ) : (
              <></>
            )}
            <Button sx={{ width: "80%" }}>
              <Box sx={{ width: "100%" }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow key={"keytopingamt" + Math.random()}>
                      <TableCell
                        align="right"
                        sx={{
                          backgroundColor: "#fff",
                          border: "0px solid #ff8404",
                          color: "#ff8404",
                        }}
                      >
                        Amount
                        <br />
                        {sizeamt}
                      </TableCell>

                      <TableCell
                        align="right"
                        sx={{
                          backgroundColor: "#fff",
                          border: "0px solid #ff8404",
                          color: "#ff8404",
                        }}
                      >
                        Topping
                        <br />
                        {toppingamt}
                      </TableCell>

                      <TableCell
                        align="right"
                        sx={{
                          backgroundColor: "#fff",
                          border: "0px solid #ff8404",
                          color: "#ff8404",
                        }}
                      >
                        Total
                        <br />
                        {selectedamount}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Box>
            </Button>
            {!showtoppings ? (
              <Button
                onClick={() => {
                  selectobj(itemdata, "A", 1);
                }}
                sx={{
                  color: "#01c0c8",
                  border: "1px solid #01c0c8",
                }}
              >
                {modeltype == 1 ? "Add to cart" : "Add"}
              </Button>
            ) : (
              <></>
            )}
          </DialogActions>
        </Dialog>

        <ToastContainer theme="colored" position="bottom-center" />
      </div>
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        containerPadding={10}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover className="profile-menu" id="popover-contained">
          <Popover.Body>
            <h5>{state.loginreducer.logininfo.data.resultSet.agentname}</h5>
            <p className="email_name">
              Mobile- {state.loginreducer.logininfo.data.resultSet.usermobile}
            </p>
            <p className="email_name">
              Extension-
              {
                JSON.parse(
                  state.loginreducer.logininfo.data.resultSet.configdata
                )[0].agentextension
              }
            </p>
            <Button
              variant="outlined"
              color="error"
              style={{
                fontSize: "15px",
                textTransform: "none",
              }}
              onClick={logout}
            >
              Sign out
            </Button>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getItemlist: (data) => dispatch(getItemlist(data)),
    createorder: (data) => dispatch(createorder(data)),
    calloperationsinfo: (data) => dispatch(calloperations(data)),
    agentstatusupdate: (data) => dispatch(agentstatusupdate(data)),
    conferencecall: (data) => dispatch(makeconferencecall(data)),
    getItemddl: (data) => dispatch(GetItemddl(data)),
    getCustname: (data) => dispatch(getCustname(data)),
    loaditeminfo: (data) => dispatch(Loaditeminfo(data)),
    loadPizzasize: (data) => dispatch(Loadpizzasize(data)),
    loadPizzabase: (data) => dispatch(Loadpizzabase(data)),
    loadPizzatoppings: (data) => dispatch(Loadpizzatoppings(data)),
    playaudiofile: (data) => dispatch(Playaudiofile(data)),
    stopaudiofile: (data) => dispatch(Stopaudiofile(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
