import { LOG_IN, ACTIVE_TAB } from "../types";
const initialState = {
  logininfo: { status: 0, message: "", totalrows: 0, resutlSet: {} },
  currenttab: "dashbord",
  fund: [],
};
const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN:
      return {
        ...state,
        logininfo: action.payload,
      };
    case ACTIVE_TAB:
      return { ...state, currenttab: action.payload };
    default:
      return state;
  }
};
export default AppReducer;
