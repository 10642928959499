import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import istudiologo from "../../images/vslogo_small.png";
import "./login.css";
import { validatelogin, agentstatusupdate } from "../../store/action/index";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const Login = ({ validatelogin, agentstatusupdate, state }) => {
  useEffect(() => {
    document.getElementById("tblcallcontainter").style.display = "none";
  }, []);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  let navigate = useNavigate();
  const signInHandler = (event) => {
    event.preventDefault();
    if (username !== undefined && username !== "") {
      if (password !== undefined && password !== "") {
        let params = {
          username: username,
          userpassword: password,
        };
        validatelogin(params)
          .then((response) => {
            console.log("Login response", response);
            if (response.data.resultSet.agentid > 0) {
              window.localStorage.setItem("islogedin", true);
              navigate("/home");
              toast.success(
                "Welcome back " + response.data.resultSet.agentname,
                {
                  autoClose: 1000,
                }
              );

              if (window.localStorage) {
                window.localStorage.setItem(
                  "org.doubango.identity.display_name",
                  response.data.resultSet.agentname
                );
                window.localStorage.setItem(
                  "org.doubango.identity.impi",
                  JSON.parse(response.data.resultSet.configdata)[0]
                    .agentextension
                  // "3001"
                );
                window.localStorage.setItem(
                  "org.doubango.identity.impu",
                  JSON.parse(response.data.resultSet.configdata)[0]
                    .publicidentity
                  //"SIP:3001@webrtctest.voicehuddle.com"
                );
                window.localStorage.setItem(
                  "org.doubango.identity.password",
                  JSON.parse(response.data.resultSet.configdata)[0]
                    .agentextensionpassword
                  //"3001"
                );
                window.localStorage.setItem(
                  "org.doubango.identity.realm",
                  JSON.parse(response.data.resultSet.configdata)[0].realm
                  // "webrtctest.voicehuddle.com"
                );

                window.localStorage.setItem(
                  "org.doubango.expert.disable_video",
                  "true"
                );
                window.localStorage.setItem(
                  "org.doubango.expert.enable_rtcweb_breaker",
                  "true"
                );
                // if (!txtWebsocketServerUrl.disabled) {
                window.localStorage.setItem(
                  "org.doubango.expert.websocket_server_url",
                  JSON.parse(response.data.resultSet.configdata)[0]
                    .websocketserverurl
                  //"wss://webrtctest.voicehuddle.com:8089/ws"
                );
                // }
                window.localStorage.setItem(
                  "org.doubango.expert.sip_outboundproxy_url",
                  JSON.parse(response.data.resultSet.configdata)[0].proxyurl
                  //"UPD://webrtctest.voicehuddle.com:5060"
                );
                window.localStorage.setItem(
                  "org.doubango.expert.ice_servers",
                  JSON.parse(response.data.resultSet.configdata)[0].iceservers
                  // '{url:"stun:stun.sipgate.net:3478"}'
                );
                window.localStorage.setItem(
                  "org.doubango.expert.bandwidth",
                  JSON.parse(response.data.resultSet.configdata)[0].bandwidth
                  // " {audio:64,video:512}"
                );
                window.localStorage.setItem(
                  "org.doubango.expert.video_size",
                  JSON.parse(response.data.resultSet.configdata)[0].videosize
                  // "{minWidth:640,minHeight:480,maxWidth:640,maxHeight:480}"
                );
                window.localStorage.setItem(
                  "org.doubango.expert.disable_early_ims",
                  "true"
                );
                window.localStorage.setItem(
                  "org.doubango.expert.disable_debug",
                  "true"
                );
                window.localStorage.setItem(
                  "org.doubango.expert.enable_media_caching",
                  "true"
                );
                window.localStorage.setItem(
                  "org.doubango.expert.disable_callbtn_options",
                  "true" // disablebuttonoption ? "true" : "false"
                );
                sipRegister();
                const interval = setInterval(function () {
                  if (
                    document.getElementById("txtRegStatus").innerText ===
                    "Connected"
                  ) {
                    //console.log(response.data.resultSet.configdata);
                    let param = {
                      agentid:response.data.resultSet.agentid,
                      extension: JSON.parse(response.data.resultSet.configdata)[0].agentextension,
                      registered: "Connected",
                    };

                    agentstatusupdate(param)
                      .then((res) => {
                        //console.log("res", res);
                      })
                      .catch((ex) => {
                        //console.log(ex);
                      });
                    clearInterval(interval);
                  }
                }, 1000);
                //txtRegStatus
              }
              // if (response.data.resultSet[0].status === 1) {
              //   if (response.data.resultSet[0].password === 0) {
              //     navigate("/Home");
              //     toast.success(response.data.resultSet[0].message, {
              //       autoClose: 1000,
              //     });
              //   } else {
              //     navigate("/changepassword");
              //   }
              // } else if (response.data.status === 2) {
              //   toast.warning(response.data.resultSet[0].message, {
              //     autoClose: 2000,
              //   });
              // } else
              //   toast.error(response.data.resultSet[0].message, {
              //     autoClose: 2000,
              //   });
            } else {
              toast.error("Sorry " + response.data.resultSet.username, {
                autoClose: 2000,
              });
            }
          })
          .catch((error) => {
            console.log("error: ", error);
          });
      } else {
        alert("password");
      }
    } else {
      alert("username");
    }
  };

  return (
    <div className="login-form">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 login-page">
            <div className="form-title">
              <img src={istudiologo} height="160px" />
              <h1>Welcome to Phone Order System</h1>
            </div>
          </div>
          <div className="col-6 form-info">
            <h6 className="form-heading">Sign Into Your Account</h6>
            <div className="form-input">
              <input
                id="emp-icon"
                className="employee"
                required
                type="text"
                placeholder="Username"
                autoComplete="off"
                tabIndex="1"
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
              <input
                id="pass-icon"
                type="password"
                className="employee"
                required
                placeholder="Password"
                tabIndex="2"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                onKeyDownCapture={(e) => {
                  if (e.which === 13) {
                    signInHandler(e);
                  }
                }}
              />
            </div>
            <button
              className="signin-btn"
              tabIndex="3"
              data-disable-with="Signing in..."
              onClick={signInHandler}
            >
              Login
            </button>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" />
    </div>
  );
};
const mapStateToProps = (state) => ({
  state: state,
});
const mapDispatchToProps = (dispatch) => {
  return {
    validatelogin: (data) => dispatch(validatelogin(data)),
    agentstatusupdate: (data) => dispatch(agentstatusupdate(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
