import { useState, forwardRef, useEffect } from "react";
import "../../common/common.css";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Stack,
  FormControl,
  Paper,
  TextField,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ToastContainer, toast } from "react-toastify";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Expertsettings = ({ handleClose }) => {
  // var sTransferNumber;
  // var oRingTone, oRingbackTone;
  // var oSipStack1,
  //   oSipSessionRegister1,
  //   oSipSessionCall1 = null,
  //   oSipSessionTransferCall1;
  // var oConfigCall1;
  // const [oSipStack, setoSipStack] = useState(null);
  // const [oSipSessionRegister, setoSipSessionRegister] = useState(null);
  // const [oSipSessionCall, setoSipSessionCall] = useState(null);
  // const [oSipSessionTransferCall, setoSipSessionTransferCall] = useState(null);
  // const [oConfigCall, setoConfigCall] = useState(null);
  var videoRemote, videoLocal, audioRemote;
  // var bFullScreen = false;
  // var oNotifICall;
  // var bDisableVideo = false;
  // var viewVideoLocal, viewVideoRemote, viewLocalScreencast; // <video> (webrtc) or <div> (webrtc4all)

  // var oReadyStateTimer;

  // var C = {
  //   divKeyPadWidth: 220,
  // };
  useEffect(() => {
    window.console &&
      window.console.info &&
      window.console.info("location=" + window.location);

    videoLocal = document.getElementById("video_local");
    videoRemote = document.getElementById("video_remote");
    audioRemote = document.getElementById("audio_remote");

    document.onkeyup = onkeyup;
    document.body.onkeyup = onkeyup;
    // divCallCtrl.onmousemove = onDivCallCtrlMouseMove;

    // set debug level
    SIPml.setDebugLevel(
      window.localStorage &&
        window.localStorage.getItem("org.doubango.expert.disable_debug") ==
          "true"
        ? "error"
        : "info"
    );

    // loadCredentials();
    //();

    // Initialize call button
    //uiBtnCallSetText("Call");

    /*if (document.readyState === "complete") {
      preInit();
  }
  else {
      document.onreadystatechange = function () {
           if (document.readyState === "complete") {
              preInit();
          }
      }
 }*/
  }, []);
  const temphandleClose = () => {
    handleClose();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const settingsSave = () => {
    window.localStorage.setItem(
      "org.doubango.expert.disable_video",
      disablevideo ? "true" : "false"
    );
    window.localStorage.setItem(
      "org.doubango.expert.enable_rtcweb_breaker",
      enablertcweb ? "true" : "false"
    );
    // if (!txtWebsocketServerUrl.disabled) {
    window.localStorage.setItem(
      "org.doubango.expert.websocket_server_url",
      serverurl
    );
    // }
    window.localStorage.setItem(
      "org.doubango.expert.sip_outboundproxy_url",
      proxyurl
    );
    window.localStorage.setItem("org.doubango.expert.ice_servers", iceservers);
    window.localStorage.setItem("org.doubango.expert.bandwidth", bandwidth);
    window.localStorage.setItem("org.doubango.expert.video_size", videosize);
    window.localStorage.setItem(
      "org.doubango.expert.disable_early_ims",
      earlyims ? "true" : "false"
    );
    window.localStorage.setItem(
      "org.doubango.expert.disable_debug",
      debugmessages ? "true" : "false"
    );
    window.localStorage.setItem(
      "org.doubango.expert.enable_media_caching",
      mediastream ? "true" : "false"
    );
    window.localStorage.setItem(
      "org.doubango.expert.disable_callbtn_options",
      "true" // disablebuttonoption ? "true" : "false"
    );

    // txtInfo.innerHTML = "<i>Saved</i>";
  };
  const settingsRevert = (bNotUserAction) => {
    setDisablevideo(
      window.localStorage.getItem("org.doubango.expert.disable_video") === true
    );
    setEnablertcweb(
      window.localStorage.getItem(
        "org.doubango.expert.enable_rtcweb_breaker"
      ) === true
    );
    setServerurl(
      window.localStorage.getItem("org.doubango.expert.websocket_server_url") ||
        ""
    );
    setProxyurl(
      window.localStorage.getItem(
        "org.doubango.expert.sip_outboundproxy_url"
      ) || ""
    );
    setIceservers(
      window.localStorage.getItem("org.doubango.expert.ice_servers") || ""
    );
    setBandwidth(
      window.localStorage.getItem("org.doubango.expert.bandwidth") || ""
    );
    setVideosize(
      window.localStorage.getItem("org.doubango.expert.video_size") || ""
    );
    setEarlyims(
      window.localStorage.getItem("org.doubango.expert.disable_early_ims") ==
        true
    );
    setDebugmessages(
      window.localStorage.getItem("org.doubango.expert.disable_debug") == true
    );
    setMediastream(
      window.localStorage.getItem("org.doubango.expert.enable_media_caching") ==
        true
    );
    setDisablebuttonoption(
      window.localStorage.getItem(
        "org.doubango.expert.disable_callbtn_options"
      ) == true
    );
    if (!bNotUserAction) {
      toast.error("Reverted", {
        autoClose: 2000,
      });
      // txtInfo.innerHTML = "<i>Reverted</i>";
    }
  };
  function saveCredentials() {
    if (window.localStorage) {
      window.localStorage.setItem(
        "org.doubango.identity.display_name",
        displayname
      );
      window.localStorage.setItem(
        "org.doubango.identity.impi",
        privateidentity
      );
      window.localStorage.setItem("org.doubango.identity.impu", publicidentity);
      window.localStorage.setItem("org.doubango.identity.password", password);
      window.localStorage.setItem("org.doubango.identity.realm", realm);
    }
  }
  const [btnvalue, setBtnvalue] = useState("Login");
  const [value, setValue] = useState(0);
  const [disablevideo, setDisablevideo] = useState(false);
  const [enablertcweb, setEnablertcweb] = useState(false);
  const [serverurl, setServerurl] = useState("");
  const [proxyurl, setProxyurl] = useState("");
  const [iceservers, setIceservers] = useState("");
  const [bandwidth, setBandwidth] = useState("");
  const [videosize, setVideosize] = useState("");
  const [earlyims, setEarlyims] = useState(false);
  const [debugmessages, setDebugmessages] = useState(false);
  const [mediastream, setMediastream] = useState(false);
  const [disablebuttonoption, setDisablebuttonoption] = useState(true);
  const [displayname, setDisplayname] = useState("");
  const [privateidentity, setPrivateidentity] = useState("");
  const [publicidentity, setPublicidentity] = useState("");
  const [password, setPassword] = useState("");
  const [realm, setRealm] = useState("");
  const [callbtndisabled, setCallbtndisabled] = useState(false);
  const [disconbtndisabled, setDisconbtndisabled] = useState(false);
  const [btnmakecallvalue, setBtnmakecallvalue] = useState("Call");
  const [btnmakecalldisabled, setBtnmakecalldisabled] = useState(false);
  const [btnhangupvalue, setHangupvalue] = useState("Hangup");
  const [btnhangupdisabled, setHangupdisabled] = useState(false);
  const [mobilenumber, setMobilenumber] = useState("");

  return (
    <div style={{ overflow: "hidden" }}>
      <AppBar
        sx={{
          position: "fixed",
          top: "auto",
          bottom: 0,
          backgroundColor: "#fff",
          left: "70%",
          width: "30%",
          // display: value == 0 ? "none" : "block",
        }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}></Box>
          {value == 1 ? (
            <Stack direction="row" spacing={2}>
              <FormControl
                sx={{
                  minWidth: 120,
                }}
                size="small"
              >
                <Button
                  variant="outlined"
                  className="endcall-bttn"
                  color="error"
                  startIcon={<LockOutlinedIcon />}
                  onClick={() => {
                    sipUnRegister();
                  }}
                >
                  Logout
                </Button>
              </FormControl>
              <FormControl
                sx={{
                  minWidth: 120,
                }}
                size="small"
              >
                <Button
                  color="success"
                  variant="outlined"
                  className="call-bttn"
                  startIcon={<LockOpenOutlinedIcon />}
                  disabled={callbtndisabled}
                  onClick={() => {
                    saveCredentials();
                  }}
                >
                  {btnvalue}
                </Button>
              </FormControl>
            </Stack>
          ) : (
            <Stack direction="row" spacing={2}>
              <FormControl
                sx={{
                  minWidth: 120,
                }}
                size="small"
              >
                <Button
                  variant="outlined"
                  className="endcall-bttn"
                  color="error"
                  startIcon={<HistoryOutlinedIcon />}
                  onClick={settingsRevert}
                >
                  Revert
                </Button>
              </FormControl>
              <FormControl
                sx={{
                  minWidth: 120,
                }}
                size="small"
              >
                <Button
                  color="success"
                  variant="outlined"
                  className="call-bttn"
                  startIcon={<SaveOutlinedIcon />}
                  onClick={settingsSave}
                >
                  Save
                </Button>
              </FormControl>
            </Stack>
          )}
        </Toolbar>
      </AppBar>
      <AppBar
        sx={{
          position: "fixed",
          top: 0,
          bottom: "auto",
          backgroundColor: "#01c0c8",
          left: "70%",
          width: "30%",
          // color: "#333333",
        }}
      >
        <Toolbar>
          <Typography
            component={"span"}
            sx={{ ml: 2, flex: 1, fontSize: "1.25rem", fontWeight: "500" }}
          >
            Settings
          </Typography>
          <Box sx={{ flexGrow: 1 }}></Box>
          <IconButton
            edge="start"
            color="inherit"
            onClick={temphandleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          "& > :not(style)": {
            mt: 8,
            ml: 5,
            mr: 5,
            width: "50%",
            height: "100%",
          },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab label="Expert settings" {...a11yProps(0)} />
          <Tab label="Make call" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} sx={{ mt: -1 }}>
        <Paper elevation={0}>
          <Stack direction="row" spacing={0} sx={{ mt: -2, ml: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={disablevideo}
                  onChange={() => {
                    setDisablevideo(!disablevideo);
                  }}
                  name="disablevideo"
                />
              }
              label="Disable Video"
            />

            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={enablertcweb}
                  onChange={() => {
                    setEnablertcweb(!enablertcweb);
                  }}
                  name="enablertcwebbreaker"
                />
              }
              label="Enable RTCWeb Breaker"
            />
          </Stack>
          <Stack direction="row" spacing={0} sx={{ mt: 1, ml: 0 }}>
            <TextField
              sx={{ minWidth: 300 }}
              size="small"
              label="WebSocket Server URL"
              variant="outlined"
              autoComplete="off"
              value={serverurl}
              onChange={(event) => {
                setServerurl(event.target.value);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ mt: 1, ml: 0 }}>
            <TextField
              sx={{ minWidth: 300 }}
              size="small"
              label="SIP outbound Proxy URL"
              variant="outlined"
              autoComplete="off"
              value={proxyurl}
              onChange={(event) => {
                setProxyurl(event.target.value);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ mt: 1, ml: 0 }}>
            <TextField
              sx={{ minWidth: 300 }}
              size="small"
              label="ICE Servers"
              variant="outlined"
              autoComplete="off"
              value={iceservers}
              onChange={(event) => {
                setIceservers(event.target.value);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ mt: 1, ml: 0 }}>
            <TextField
              sx={{ minWidth: 300 }}
              size="small"
              label="Max bandwidth (kbps)"
              variant="outlined"
              autoComplete="off"
              value={bandwidth}
              onChange={(event) => {
                setBandwidth(event.target.value);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ mt: 1, ml: 0 }}>
            <TextField
              sx={{ minWidth: 300 }}
              size="small"
              label="Video size"
              variant="outlined"
              autoComplete="off"
              value={videosize}
              onChange={(event) => {
                setVideosize(event.target.value);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ mt: 0, ml: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={earlyims}
                  onChange={() => {
                    setEarlyims(!earlyims);
                  }}
                  name="disable3GPPearlyIMS"
                />
              }
              label="Disable 3GPP Early IMS"
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ mt: 0, ml: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={debugmessages}
                  onChange={() => {
                    setDebugmessages(!debugmessages);
                  }}
                  name="disabledebugmessages"
                />
              }
              label="Disable debug messages"
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ mt: 0, ml: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={mediastream}
                  onChange={() => {
                    setMediastream(!mediastream);
                  }}
                  name="Cachethemediastream"
                />
              }
              label="Cache the media stream"
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ mt: 0, ml: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={disablebuttonoption}
                  onChange={() => {
                    setDisablebuttonoption(!disablebuttonoption);
                  }}
                  name="disableCallbuttonoptions"
                />
              }
              label="Disable Call button options"
            />
          </Stack>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper elevation={0}>
          <Stack direction="row" spacing={1} sx={{ ml: 2 }}>
            <TextField
              sx={{ minWidth: 300 }}
              size="small"
              label="Display Name"
              variant="outlined"
              autoComplete="off"
              value={displayname}
              onChange={(event) => {
                setDisplayname(event.target.value);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={0} sx={{ mt: 2, ml: 2 }}>
            <TextField
              sx={{ minWidth: 300 }}
              size="small"
              required
              label="Private Identity"
              variant="outlined"
              autoComplete="off"
              value={privateidentity}
              onChange={(event) => {
                setPrivateidentity(event.target.value);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={0} sx={{ mt: 2, ml: 2 }}>
            <TextField
              sx={{ minWidth: 300 }}
              required
              size="small"
              label="Public Identity"
              variant="outlined"
              autoComplete="off"
              value={publicidentity}
              onChange={(event) => {
                setPublicidentity(event.target.value);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ mt: 2, ml: 2 }}>
            <TextField
              sx={{ minWidth: 300 }}
              size="small"
              label="Password"
              variant="outlined"
              autoComplete="off"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ mt: 2, ml: 2 }}>
            <TextField
              sx={{ minWidth: 300 }}
              required
              size="small"
              label="Realm"
              variant="outlined"
              autoComplete="off"
              value={realm}
              onChange={(event) => {
                setRealm(event.target.value);
              }}
            />
          </Stack>
        </Paper>
      </TabPanel>
      <ToastContainer theme="colored" />
    </div>
  );
};
export default Expertsettings;
