import {
  LOG_IN,
  BANK_INFO,
  PVD,
  ACTIVE_TAB,
  FUND_CONF,
  SUPPLIER_INFO,
} from "../types";

import axios from "axios";
let jsonconfig = {
  headers: {
    "Content-Type": "application/json",
  },
};
let formconfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
const baseUrl = "https://phoneorder.co/callcenter/api/"; //"https://localhost:44378/api/";
//https://callorder.voicesnap.com
//https://ap.voicehuddle.com
export const validatelogin = (ipval) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "VSR/ValidateagentLogin",
      JSON.stringify(ipval),
      jsonconfig
    );

    dispatch({
      type: LOG_IN,
      payload: response.data,
    });
    dispatch({ type: ACTIVE_TAB, payload: "dashboard" });
    return response.data;
  } catch (ex) {}
};
export const getItemlist = (ipval) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "order/getfinisheditems",
      JSON.stringify(ipval),
      jsonconfig
    );
    return response.data;
  } catch (ex) {}
};
//getItemddl
export const GetItemddl = (ipval) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "order/loadmasteritemddl",
      JSON.stringify(ipval),
      jsonconfig
    );
    return response.data;
  } catch (ex) {}
};
export const agentstatusupdate = (ipval) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "callstatus/agentstausupdate",
      JSON.stringify(ipval),
      jsonconfig
    );
    return response.data;
  } catch (ex) {}
};

export const calloperations = (ipval) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "callstatus/agentcallstatus",
      ipval,
      jsonconfig
    );
    return response.data;
  } catch (ex) {}
};
export const createorder = (ipval) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "order/createorder", // "https://localhost:44378/api/order/createorder", //baseUrl + "order/createorder",
      ipval,
      jsonconfig
    );
    return response.data;
  } catch (ex) {}
};

export const makeconferencecall = (ipval) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "order/makeconferencecall", // "https://localhost:44378/api/order/makeconferencecall", // "order/makeconferencecall", //  baseUrl + "order/createorder",
      ipval,
      jsonconfig
    );
    return response.data;
  } catch (ex) {}
};
export const Playaudiofile = (ipval) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "order/playaudiofile",
      ipval,
      jsonconfig
    );
    return response.data;
  } catch (ex) {}
};
export const Stopaudiofile = (ipval) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "order/stopaudiofile",
      ipval,
      jsonconfig
    );
    return response.data;
  } catch (ex) {}
};
//
export const getCustname = (ipval) => async (dispatch) => {
  try {
    const response = await axios.get(
      baseUrl +
        "order/getconsumername?consumermobile=" +
        ipval.custmobile +
        "&customermobile=" +
        ipval.did
    );
    return response.data;
  } catch (ex) {}
};
export const changePassword = (ipval) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "login/changepassword",
      JSON.stringify(ipval),
      jsonconfig
    );
    return response.data;
  } catch (ex) {}
};
export const addUsers = (ipval) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "masters/addusers",
      JSON.stringify(ipval),
      jsonconfig
    );
    dispatch({ type: ACTIVE_TAB, payload: "users" });
    return response.data;
  } catch (ex) {}
};

export const loadUsers = (ipval) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "masters/loadusers",
      JSON.stringify(ipval),
      jsonconfig
    );
    dispatch({ type: ACTIVE_TAB, payload: "users" });
    return response.data;
  } catch (ex) {}
};
export const Loaditeminfo = (itemcode) => async (dispatch) => {
  
  try {
    const response = await axios.get(
      baseUrl + "Pizzacorner/Loaditeminfo?itemcode=" + itemcode,
      jsonconfig
    );
    dispatch({ type: ACTIVE_TAB, payload: "users" });
    return response.data;
  } catch (ex) {}
};
// , Consolas, 'Courier New', monospace
export const Loadpizzasize = (itemcode) => async (dispatch) => {
  try {
    const response = await axios.post(
      baseUrl + "VSR/Loaditemsize",
      itemcode,
      jsonconfig
    );
    dispatch({ type: ACTIVE_TAB, payload: "users" });
    return response.data;
  } catch (ex) {}
};
export const Loadpizzabase = (item) => async (dispatch) => {
  try {
    const response = await axios.get(
      baseUrl + "Pizzacorner/Loadpizzabase?restraurantid=" + item.restraurantid,
      jsonconfig
    );
    dispatch({ type: ACTIVE_TAB, payload: "users" });
    return response.data;
  } catch (ex) {}
};
export const Loadpizzatoppings = (item) => async (dispatch) => {
  try {
    const response = await axios.get(
      baseUrl +
        "Pizzacorner/Loadpizzatoppings?restraurantid=" +
        item.restraurantid +
        "&userid=" +
        item.userid,
      jsonconfig
    );
    dispatch({ type: ACTIVE_TAB, payload: "users" });
    return response.data;
  } catch (ex) {}
};
