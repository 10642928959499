import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/login/login";
import Home from "./components/common/home";
//import { GlobalDebug } from "./components/common/remove-consoles";
//import { useEffect } from "react";

function App() {
    // useEffect(() => {
    //     (process.env.NODE_ENV === "production" ||
    //      process.env.REACT_APP_ENV === "STAGING") &&
    //       GlobalDebug(true);
    //   }, []);
  return (
    <div className="App">
        {/* basename="/agent */}
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/home" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
